import { FC, ReactNode, SyntheticEvent, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { Link as MaterialLink, SxProps, Theme } from '@mui/material';

import { styles } from './styles';

interface LinkProps {
  path?: string;
  text?: string;
  trackType?: AnalyticsLinkType;
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

export const Link: FC<LinkProps> = ({ path, text = '', sx, trackType: type = AnalyticsLinkType.LINK, children }) => {
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();
  const navigate = useNavigate();
  const handleLinkClick = useCallback(
    (e: SyntheticEvent) => {
      if (path) {
        e.stopPropagation();
        e.preventDefault();
        navigate(path);
        handleTrackLinkClickEvent({ path, text, type });
      }
    },
    [text, path, type, handleTrackLinkClickEvent, navigate],
  );
  if (!path) {
    return <>{children}</>;
  }

  return (
    <MaterialLink href={path} onClick={handleLinkClick} sx={{ ...styles.link, ...(sx || {}) }}>
      {children}
    </MaterialLink>
  );
};
