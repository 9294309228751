import { ReactElement } from 'react';

import { Box, Typography } from '@mui/material';
import { generatePath } from 'react-router-dom';

import flower from 'assets/images/flower.png';
import ornamental from 'assets/images/ornamental.png';
import oilseed from 'assets/images/oilseed.png';
import vegetable from 'assets/images/carrotSlanted.png';
import herb from 'assets/images/herb.png';
import forage from 'assets/images/forage.png';
import grass from 'assets/images/grass.png';
import grain from 'assets/images/grain.png';
import fruit from 'assets/images/openWatermelonReverse.png';

import { useCropFamiliesQuery } from 'hooks';
import { Link } from 'components/Link';
import { ROUTES } from 'app/routes/constants';
import { styles } from './styles';

const getPath = (type: string) => generatePath(ROUTES.buyer.products, { type });

const categories = [
  { name: 'Vegetable', logo: vegetable, path: getPath('Vegetable') },
  { name: 'Fruit', logo: fruit, path: getPath('Fruit') },
  { name: 'Grain', logo: grain, path: getPath('Grain') },
  { name: 'Forage', logo: forage, path: getPath('Forage') },
  { name: 'Oilseed', logo: oilseed, path: getPath('Oilseed') },
  { name: 'Herb', logo: herb, path: getPath('Herb') },
  { name: 'Grass', logo: grass, path: getPath('Grass') },
  { name: 'Flower', logo: flower, path: getPath('Flower') },
  { name: 'Ornamental', logo: ornamental, path: getPath('Ornamental') },
];

export const Categories = (): ReactElement => {
  const { cropFamiliesData, isCropFamiliesFetching, isCropFamiliesLoading } = useCropFamiliesQuery({
    enabled: false,
  });

  const validCategories = cropFamiliesData.reduce(
    (categories, cropCategory) => categories.add(cropCategory.name),
    new Set(),
  );

  const displayCategories =
    isCropFamiliesFetching || isCropFamiliesLoading ? [] : categories.filter((c) => validCategories.has(c.name));

  return (
    <Box component="section" sx={styles.container}>
      {displayCategories.map((category) => (
        <Link key={category.name} path={category.path} text={category.name}>
          <Box key={category.name} sx={styles.categoryInnerContainer}>
            <Typography sx={styles.name}>{category.name}</Typography>
            <Box component="img" src={category.logo} alt={category.name} sx={styles.logo} />
          </Box>
        </Link>
      ))}
    </Box>
  );
};
