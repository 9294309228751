import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '50%',
    },
  },
  avatar: {
    width: '80px',
    height: '80px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '48px',
      height: '48px',
    },
  },
  role: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '10px',
      lineHeight: '14px',
    },
  },
  name: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.gray.G500,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '8px',
      lineHeight: '12px',
    },
  },
};
