import { FC, useMemo } from 'react';

import { Box } from '@mui/material';
import Avatar from '@sendbird/uikit-react/ui/Avatar';
import { ClientFileMessage, ClientSentMessages } from 'SendbirdUIKitGlobal';

import { SBClientUserMessage, SBMemberMetaData } from 'app/api/SBChat';
import defaultPrimaryAvatar from 'assets/icons/avatarPlaceholderPrimary.svg';
import defaultSecondaryAvatar from 'assets/icons/avatarPlaceholderSecondary.svg';
import defaultSystemAvatar from 'assets/icons/avatarPlaceholderGray.svg';
import { useAuth } from 'hooks';
import { getLastMessageDate } from 'utils/getLastMessageDate';
import { getDateTime } from 'utils/getDateTime';
import { SearchUserMessageItem } from '../SearchUserMessageItem';
import { SearchMessageItemHeader } from '../SearchMessageItemHeader';
import { SearchSystemMessageItem } from '../SearchSystemMessageItem';
import { SearchFileMessageItem } from '../SearchFileMessageItem';
import { styles } from './styles';

interface SearchMessageItemProps {
  isSelected: boolean;
  message: ClientSentMessages;
  handleClick?: (message: ClientSentMessages) => void;
}

export const SearchMessageItem: FC<SearchMessageItemProps> = ({ message, isSelected, handleClick }) => {
  const { sendbirdUserId, activeUserCompanyId } = useAuth();

  const data = message as unknown as SBClientUserMessage;

  const isUserType = data.messageType === 'user' && !data.silent;
  const isSystemType = data.messageType === 'admin' || (data.messageType === 'user' && !!data.silent);
  const isFileType = data.messageType === 'file';

  const date = getLastMessageDate(data.createdAt);
  const time = getDateTime(data?.createdAt);

  const isCurrentUserAuthor = data?.sender?.userId === sendbirdUserId;
  const isCurrentCompanyAuthor = (data?.sender?.metaData as SBMemberMetaData)?.company_id === activeUserCompanyId;

  const avatarPlaceholder =
    isCurrentUserAuthor || isCurrentCompanyAuthor ? defaultPrimaryAvatar : defaultSecondaryAvatar;
  const avatar =
    data.messageType === 'user' || data.messageType === 'file'
      ? data?.sender?.plainProfileUrl || avatarPlaceholder
      : undefined;
  const avatarSrc = isSystemType ? defaultSystemAvatar : avatar;

  const userName = data.messageType === 'user' || data.messageType === 'file' ? data?.sender?.nickname : undefined;

  const messageComponent = useMemo(() => {
    if (isUserType) {
      return <SearchUserMessageItem body={data.message} />;
    }

    if (isSystemType) {
      return <SearchSystemMessageItem body={data.message} />;
    }

    if (isFileType) {
      return (
        <SearchFileMessageItem
          isCurrentUser={isCurrentUserAuthor}
          isCurrentCompany={isCurrentCompanyAuthor}
          message={message as ClientFileMessage}
        />
      );
    }

    return <></>;
  }, [isUserType, isSystemType, isFileType, data.message, isCurrentUserAuthor, isCurrentCompanyAuthor, message]);

  return (
    <Box
      sx={styles.wrap(isSelected)}
      onClick={() => {
        handleClick?.(message);
      }}
    >
      {!!avatarSrc && <Avatar src={avatarSrc} width="40px" height="40px" />}
      <Box sx={styles.inner}>
        <SearchMessageItemHeader
          isCurrentUser={isCurrentUserAuthor}
          isSystem={isSystemType}
          userName={userName}
          date={date}
          time={time}
        />
        {messageComponent}
      </Box>
    </Box>
  );
};
