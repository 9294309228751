import { FC } from 'react';

import { Box } from '@mui/material';

import { QuoteProduct, UnitsType } from 'app/api/quotes/types';
import { OrderCropsListItem } from 'components';
import { styles } from './styles';

interface CropsListProps {
  products: QuoteProduct[];
  unitsType: UnitsType;
}

export const CropsList: FC<CropsListProps> = ({ products, unitsType }): JSX.Element => {
  return (
    <Box sx={{ ...styles.wrap }} data-testid="crops-list">
      {products.map((crop) => (
        <OrderCropsListItem
          key={crop.id}
          cropName={crop.product.crop}
          variety={crop.product.variety}
          name={crop.product.name}
          price={Number(crop?.acceptedPrice ?? crop?.price)}
          amount={crop.amount}
          unitsType={unitsType}
        />
      ))}
    </Box>
  );
};
