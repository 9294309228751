import { FC, useCallback } from 'react';

import { Box, Typography } from '@mui/material';

import { useAuth } from 'hooks';
import { requiredFieldValidation } from 'app/validation/validation';
import { ReactComponent as EmptyIcon } from 'assets/icons/noResult.svg';
import { useForm } from 'react-hook-form';
import { CommonButton, LoadingButton, TextFormField } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { FeedbackFormValues } from '../FeedbackFormPopup/types';
import { useFeedbackFormMutation } from '../FeedbackFormPopup/hooks/useFeedbackFormMutation';
import { bodyFieldRules } from '../FeedbackFormPopup/validation';
import { companyNames } from './companyNames';
import { styles } from './styles';

const messages = {
  search: {
    title: 'No product found',
    sub: "We didn't find any matches for your request.",
  },
  company: {
    title: 'Search by company unavailable',
    sub: 'Please login to view',
  },
  cantFind: "Can't find a product?",
};

export const EmptyState: FC<{ query?: string }> = ({ query }) => {
  const { control, handleSubmit } = useForm<FeedbackFormValues>();
  const { activeUser } = useAuth();
  const isLoggedIn = !!activeUser;

  const isCompanySearch = Boolean(!isLoggedIn && query && companyNames.has(query.toLowerCase()));
  const { isCreateFeedbackFormLoading, handleCreateProductNotFoundRequest } = useFeedbackFormMutation({});
  const handleConfirmRequest = useCallback(
    (values: FeedbackFormValues) => {
      handleCreateProductNotFoundRequest(values);
    },
    [handleCreateProductNotFoundRequest],
  );

  const onClickCantFind = () => {
    const nextfield = document.querySelector(`textarea[name=msgBody]`) as HTMLInputElement;
    if (nextfield !== null && 'focus' in nextfield) {
      nextfield.focus();
    }
  };
  const descriptionText = isCompanySearch ? messages.company : messages.search;

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.notFoundContainer}>
          <EmptyIcon />
          <Box sx={styles.description(isCompanySearch)}>
            <Typography sx={styles.title}>{descriptionText.title}</Typography>
            <Typography sx={styles.text}>{descriptionText.sub}</Typography>
          </Box>
        </Box>

        <Box sx={styles.bottomSection}>
          <CommonButton onClick={onClickCantFind} sx={styles.cantFindBtn}>
            {messages.cantFind}
          </CommonButton>

          <Typography sx={styles.bottomText}>
            <Box component="b">Request any product you need below.</Box> We will message our supplier network and add
            the product to Sproutzo for you. You will be notified as soon as it is on Sproutzo.
          </Typography>
          <form onSubmit={handleSubmit(handleConfirmRequest)}>
            <TextFormField
              name="msgBody"
              placeholder="I am looking for: product, quantity, price target..."
              multiline
              rows={5}
              inputWrapStyles={{ ...(styles.inputStyles as SxPropsTypes) }}
              control={control}
              rules={bodyFieldRules}
            />
            <Box sx={styles.actions}>
              {!isLoggedIn && (
                <TextFormField
                  name="email"
                  type="email"
                  placeholder="Email"
                  rows={5}
                  fullWidth
                  rules={{ required: requiredFieldValidation }}
                  wrapStyles={{ ...(styles.emailContainer as SxPropsTypes) }}
                  inputWrapStyles={{ ...(styles.emailInput as SxPropsTypes) }}
                  control={control}
                />
              )}

              <LoadingButton
                type="submit"
                loading={isCreateFeedbackFormLoading}
                variant="contained"
                color="secondary"
                sx={styles.confirmButton}
              >
                Send to the Sproutzo team
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
