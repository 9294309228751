import { FC, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import logo from 'assets/images/headerLogo.svg';

import { getDefaultUserRoute } from 'app/providers/utils';
import { useAuth } from 'hooks';
import { CommonButton } from 'components';
import { useAppDispatch } from 'state/hooks';
import { setSignupModal } from 'state/slices/account';
import { CookieBanner } from 'components/CookieBanner';

import { UserMenu } from '../UserMenu';
import { HeaderSearch } from '../HeaderSearch';
import { NavigationContainer } from '../NavigationContainer';
import { Notifications } from '../Notifications';
import { styles } from './styles';

interface HeaderProps {
  isShadow: boolean;
  isActionsEnabled?: boolean;
}

export const Header: FC<HeaderProps> = ({ isShadow, isActionsEnabled = true }) => {
  const navigate = useNavigate();
  const { isBuyer, activeUser } = useAuth();

  const handleRedirectHome = useCallback(() => {
    navigate(getDefaultUserRoute(isBuyer));
  }, [isBuyer, navigate]);

  const dispatch = useAppDispatch();

  const onClickRequestAccess = () =>
    dispatch(setSignupModal({ isOpen: true, state: 'requestAccess', source: 'header' }));
  const onClickLogin = () => dispatch(setSignupModal({ isOpen: true, state: 'login' }));

  return (
    <Box sx={{ ...styles.mainWrap(isShadow) }}>
      <CookieBanner />

      <Box sx={{ ...styles.wrap }}>
        <Box sx={{ ...styles.leftSideWrap }}>
          <Box sx={{ ...styles.logo }}>
            <Box component="img" src={logo} onClick={handleRedirectHome} />
          </Box>

          {isActionsEnabled && (
            <>
              <Box sx={{ ...styles.spacer }} />
              <NavigationContainer />{' '}
            </>
          )}
        </Box>

        {isActionsEnabled && (
          <Box sx={{ ...styles.rightSideWrap }}>
            {(isBuyer || !activeUser) && <HeaderSearch />}
            {activeUser ? (
              <>
                <Notifications />
                <UserMenu />
              </>
            ) : (
              <>
                <CommonButton onClick={onClickRequestAccess}>Request Free Access</CommonButton>
                <CommonButton onClick={onClickLogin} color="inherit" sx={styles.login}>
                  Login
                </CommonButton>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
