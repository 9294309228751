import { FC } from 'react';

import { Box, IconButton } from '@mui/material';
import { ClientSentMessages } from 'SendbirdUIKitGlobal';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrowLeftSmall.svg';
import { SBSearchMessages } from 'components';
import { useAuth } from 'hooks';
import { CommonChatDrawer } from '../CommonChatDrawer';
import { styles } from './styles';

interface SearchPanelProps {
  channelName: string;
  channelUrl: string;
  isOpen: boolean;
  selectedMessageId?: number;
  handleClose: () => void;
  handleResultClick: (message: ClientSentMessages) => void;
}

export const SearchPanel: FC<SearchPanelProps> = ({
  channelName,
  channelUrl,
  isOpen,
  selectedMessageId,
  handleClose,
  handleResultClick,
}) => {
  const { isBuyer } = useAuth();

  return (
    <CommonChatDrawer isOpen={isOpen} drawerSx={styles.drawer} paperSx={styles.paper} handleClose={handleClose}>
      <Box sx={styles.wrap(isBuyer)}>
        <IconButton sx={styles.backButton} onClick={handleClose}>
          <ArrowLeftIcon />
        </IconButton>
        <SBSearchMessages
          channelUrl={channelUrl}
          channelName={channelName}
          selectedMessageId={selectedMessageId}
          handleResultClick={handleResultClick}
          handleToggleSearchPannel={handleClose}
        />
      </Box>
    </CommonChatDrawer>
  );
};
