import { FC } from 'react';

import { Box, Divider } from '@mui/material';

import { UserMenuOptions } from 'components';
import { useUserMenu } from 'hooks';
import { CommonDrawer } from '../CommonDrawer';
import { CloseDrawerButton } from '../CloseDrawerButton';
import { MobileSidebarHeader } from '../MobileSidebarHeader';
import { styles } from './styles';

interface UserAccountDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const UserAccountDrawer: FC<UserAccountDrawerProps> = ({ isOpen, handleClose }) => {
  const {
    isBuyer,
    isModalOpen,
    isMultiRole,
    changeUser,
    handleCloseModal,
    handleOpenCompanyProfile,
    handleOpenModal,
    handleOpenChangePassword,
  } = useUserMenu({ onAction: handleClose });

  return (
    <>
      <CommonDrawer anchor="right" isOpen={isOpen} handleClose={handleClose}>
        <MobileSidebarHeader />
        <Box sx={styles.menuWrap}>
          <UserMenuOptions
            isBuyer={isBuyer}
            isModalOpen={isModalOpen}
            isMultiRole={isMultiRole}
            changeUser={changeUser}
            handleOpenCompanyProfile={handleOpenCompanyProfile}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            handleOpenChangePassword={handleOpenChangePassword}
          />
          <Divider sx={styles.divider} />
        </Box>
      </CommonDrawer>
      {isOpen && <CloseDrawerButton position="left" handleClose={handleClose} />}
    </>
  );
};
