import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Crop, CropFamiliesData } from 'app/api/crops';
import { RootState } from 'state/store';

export type CropState = Crop & { family: { id: number; name: string } };
// Define a type for the slice state
export interface CropFamilyState {
  cropFamily: {
    [id: number]: {
      id: number;
      name: string;
      crops: Crop[];
    };
  };
  crop: {
    [id: number]: CropState;
  };
}

// Define the initial state using that type
const initialState: CropFamilyState = {
  cropFamily: {},
  crop: {},
};

export const counterSlice = createSlice({
  name: 'cropFamilies',
  initialState,
  reducers: {
    setCropFamilies: (state, action: PayloadAction<CropFamiliesData>) => {
      if (!action.payload) return;
      action.payload.forEach((family) => {
        state.cropFamily[family.id] = {
          id: family.id,
          name: family.name,
          crops: family.crops,
        };
        if (Array.isArray(family.crops)) {
          family.crops.forEach((crop) => {
            state.crop[crop.id] = {
              id: crop.id,
              name: crop.name,
              family: {
                id: family.id,
                name: family.name,
              },
            };
          });
        }
      });
    },
  },
});

export const { setCropFamilies } = counterSlice.actions;

const selectCropFamilyCrops = (state: RootState) => state.cropFamilies.crop;
export const selectCrops = createSelector(selectCropFamilyCrops, (cropFamilies) => {
  const crops = Object.values(cropFamilies);
  return crops;
});

export const selectCropByName = (cropName: string) => (state: RootState) => {
  const crop = Object.values(state.cropFamilies.crop).find((c) => c.name === cropName);
  return crop;
};

export default counterSlice.reducer;
