import { FC, useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { Quote, QuoteProductStatus, UnitsType } from 'app/api/quotes/types';
import { currencyFormat } from 'utils/currencyFormat';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { useDeviceType } from 'hooks';
import { AddressInfo } from '../AddressInfo';
import { CreateOrderActions } from '../CreateOrderActions';
import { CropsList } from '../CropsList';
import { styles } from './styles';

export interface CreateOrderWrapperProps {
  isLoading: boolean;
  quoteData: Quote | undefined;
  handleCancel: () => void;
}

export const CreateOrderWrapper: FC<CreateOrderWrapperProps> = ({
  quoteData,
  isLoading,
  handleCancel,
}): JSX.Element => {
  const { isMobileSize } = useDeviceType();

  const isSampleOrder = quoteData?.isSampleType;

  const activeProducts = useMemo(
    () => quoteData?.quoteproducts.filter((product) => product.status === QuoteProductStatus.AVAILABLE) ?? [],
    [quoteData?.quoteproducts],
  );

  const totalWeight =
    quoteData?.unitsType === UnitsType.THOUSAND_SEEDS
      ? 'Pending'
      : `${quoteData?.totalAmount ?? ''} ${getUnitDisplayValue(quoteData?.unitsType ?? UnitsType.KG, true)}`;

  const createOrderTitle = `Create ${isSampleOrder ? 'sample ' : ''} order`;
  const title = isMobileSize ? 'Order Details' : createOrderTitle;

  return (
    <Box sx={{ ...styles.wrap }}>
      <Typography sx={{ ...styles.title }}>{title}</Typography>

      {!!activeProducts.length && (
        <CropsList products={activeProducts} unitsType={quoteData?.unitsType ?? UnitsType.KG} />
      )}

      <AddressInfo seller={quoteData?.seller} />

      <Box sx={{ ...styles.total }}>
        <Typography sx={{ ...styles.totalTitle, ...styles.totalText }}>Total Weight</Typography>
        <Typography sx={{ ...styles.totalAmount, ...styles.totalText }} data-testid="total-weight">
          {totalWeight}
        </Typography>
      </Box>

      <Box sx={{ ...styles.total }}>
        <Typography sx={{ ...styles.totalTitle, ...styles.totalText }}>Total Price</Typography>
        <Typography sx={{ ...styles.totalPrice, ...styles.totalText }} data-testid="total-price">
          {currencyFormat(Number(quoteData?.totalPrice))}
        </Typography>
      </Box>

      <Box sx={styles.actionsWrap}>
        <CreateOrderActions isLoading={isLoading} handleCancel={handleCancel} />
      </Box>
    </Box>
  );
};
