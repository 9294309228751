import { getAccessInfo } from 'utils/storage';
import { contentApi, customerApi } from '../index';
import { apiRoutes } from '../apiRoutes';
import { CustomerProfile, UserData } from './types';

export const getUserData = async () => {
  const response = await contentApi.get<UserData[]>(apiRoutes.user.customers, {
    headers: { Authorization: `token ${getAccessInfo('token') ?? ''}` },
  });
  return response?.data;
};

export const getCustomerProfile = async (id: number) => {
  const response = await customerApi.get<CustomerProfile>(apiRoutes.user.customerProfile(id));

  return response?.data;
};
