import { authApi, contentApi } from '../index';
import { apiRoutes } from '../apiRoutes';
import {
  CreatePasswordRequest,
  LoginData,
  LoginResponse,
  ResetPasswordStartRequest,
  ChangePasswordRequest,
} from './types';

export const logIn = async (data: LoginData) => {
  const response = await authApi.post<LoginResponse>(apiRoutes.auth.logIn, data);
  return response?.data;
};

export const logOut = async (token: string) => {
  await authApi.post(
    apiRoutes.auth.logOut,
    {},
    {
      headers: { Authorization: `token ${token}` },
    },
  );
};

export const createPassword = async (data: CreatePasswordRequest) => {
  await authApi.post(apiRoutes.auth.createPassword, data);
};

export const resetPasswordSubmit = async (data: CreatePasswordRequest) => {
  await authApi.post(apiRoutes.auth.resetPassword.submit, data);
};

export const resetPasswordStart = async (data: ResetPasswordStartRequest) => {
  await authApi.post(apiRoutes.auth.resetPassword.start, data);
};

export const changePassword = async (data: ChangePasswordRequest) => {
  await contentApi.post(apiRoutes.auth.changePassword, data);
};
