import { FC } from 'react';

import { UseFormSetValue, FieldValues, Control } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { ProductsFiltersForm } from 'app/api/products';
import { CheckboxFilter, CommonButton } from 'components';
import { useModal } from 'hooks';
import {
  FiltersNames,
  AdditionalFiltersControlNames,
  colorFilterAll,
  continentFilterAll,
  // resistancesFilterAll,
  geneticFilterValues,
  INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT_LABEL,
  INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY_LABEL,
  WEIGHT_UNIT,
  MATURITY_UNIT,
  organicFilterValues,
} from '../../constants';
import { GeneticTypeFilter } from '../GeneticTypeFilter';
import { OrganicFilter } from '../OrganicFilter';
import { SliderFilter } from '../SliderFilter';
import { useProductsFiltersWrap } from './hooks';
import { styles } from './styles';
import { FeedbackFormPopup } from '../FeedbackFormPopup';

interface ProductsFiltersProps {
  control: Control<ProductsFiltersForm>;
  setValue: UseFormSetValue<ProductsFiltersForm>;
  isAnySelected: boolean;
  clearFilters: () => void;
  parsedColors: {
    value: string;
    label: string;
  }[];
  parsedContinents: {
    value: string;
    label: string;
  }[];
  parsedResistances: {
    value: string;
    label: string;
  }[];
  isMaturityFilter: boolean;
  isOrganicFilter: boolean;
  isWeightFilter: boolean;
  isMaturityRange: boolean;
  isWeightRange: boolean;
  minWeightLimit: number;
  maxWeightLimit: number;
  weightStep: number;
  minMaturityLimit: number;
  maxMaturityLimit: number;
  maturityStep: number;
  searchParam: string | undefined;
}

const messages = {
  cantFind: "Can't find a product?",
  requestProduct: 'Request a product',
};

export const ProductsFilters: FC<ProductsFiltersProps> = ({
  control,
  setValue,
  isAnySelected,
  clearFilters,
  parsedColors,
  parsedContinents,
  // SPZ-1550
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parsedResistances,
  isMaturityFilter,
  isOrganicFilter,
  isWeightFilter,
  isMaturityRange,
  isWeightRange,
  minWeightLimit,
  maxWeightLimit,
  weightStep,
  minMaturityLimit,
  maxMaturityLimit,
  maturityStep,
  searchParam,
}): JSX.Element => {
  const {
    handleChangeMaturityCheckBox,
    handleChangeMaturitySlider,
    handleChangeWeightCheckBox,
    handleChangeWeightSlider,
    // SPZ-1550
    // handleChangeResistancesCheckBox,
  } = useProductsFiltersWrap({ setValue, searchParam });

  const { open, handleOpenModal, handleCloseModal } = useModal();

  return (
    <Box sx={{ ...styles.wrap }}>
      <Box sx={{ ...styles.filtersWrap }}>
        <Box sx={{ ...styles.filters }}>
          <GeneticTypeFilter
            data={geneticFilterValues}
            control={control as unknown as Control}
            controlName={FiltersNames.GENETIC_TYPE}
          />
          {parsedColors.length > 1 && (
            <CheckboxFilter
              data={parsedColors}
              defaultFilter={colorFilterAll}
              defaultTitle="Color"
              control={control as unknown as Control}
              controlName={FiltersNames.COLOR}
              setValue={setValue as unknown as UseFormSetValue<FieldValues>}
            />
          )}
          <CheckboxFilter
            data={parsedContinents}
            defaultFilter={continentFilterAll}
            defaultTitle="Continent"
            control={control as unknown as Control}
            controlName={FiltersNames.CONTINENT}
            setValue={setValue as unknown as UseFormSetValue<FieldValues>}
          />
          {isWeightFilter && (
            <SliderFilter
              checkBoxControlName={AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT}
              checkboxLabel={INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT_LABEL}
              control={control}
              controlName={AdditionalFiltersControlNames.WEIGHT}
              defaultTitle="Weight"
              isRange={isWeightRange}
              min={minWeightLimit}
              max={maxWeightLimit}
              step={weightStep}
              unit={WEIGHT_UNIT}
              handleChangeCheckBox={handleChangeWeightCheckBox}
              handleChangeSlider={handleChangeWeightSlider}
            />
          )}
          {isMaturityFilter && (
            <SliderFilter
              checkBoxControlName={AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY}
              checkboxLabel={INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY_LABEL}
              control={control}
              controlName={AdditionalFiltersControlNames.MATURITY}
              defaultTitle="Maturity"
              isRange={isMaturityRange}
              min={minMaturityLimit}
              max={maxMaturityLimit}
              step={maturityStep}
              unit={MATURITY_UNIT}
              handleChangeCheckBox={handleChangeMaturityCheckBox}
              handleChangeSlider={handleChangeMaturitySlider}
            />
          )}
          {/* SPZ-1550: display filter */}
          {/* <CheckboxFilter
            data={parsedResistances}
            defaultFilter={resistancesFilterAll}
            defaultTitle="Resistances"
            control={control as unknown as Control}
            controlName={FiltersNames.RESISTANCES}
            setValue={setValue as unknown as UseFormSetValue<FieldValues>}
            isApplyFilterCheckBox
            applyFilterControlName={AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_RESISTANCES}
            applyFilterLabel="Include products with incomplete resistances data"
            handleApplyFilterChange={handleChangeResistancesCheckBox}
          /> */}
          {isOrganicFilter && (
            <OrganicFilter
              data={organicFilterValues}
              control={control as unknown as Control}
              controlName={FiltersNames.ORGANIC}
            />
          )}
        </Box>

        {isAnySelected && (
          <CommonButton variant="text" sx={{ ...styles.clearAllButton }} onClick={clearFilters}>
            Clear all filters
          </CommonButton>
        )}
      </Box>
      <Box sx={styles.requestContainer}>
        <Typography sx={styles.cantFind}>{messages.cantFind}</Typography>
        <CommonButton variant="contained" sx={styles.requestBtn} onClick={handleOpenModal}>
          {messages.requestProduct}
        </CommonButton>
        <FeedbackFormPopup isOpen={open} handleCloseModal={handleCloseModal} />
      </Box>
    </Box>
  );
};
