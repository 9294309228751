import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  container: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '24px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '8px',
      gap: '8px',
    },
  },

  categoryInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '124px',
    boxSizing: 'border-box',
    padding: '4px 8px',
    height: '48px',
    borderRadius: '24px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    color: customTheme.custom.black.B70,
    border: '1px solid',
    borderColor: customTheme.custom.black.B70,
    boxShadow: customTheme.shadow.boxShadow.small,
    ':hover': {
      boxShadow: customTheme.shadow.boxShadow.smallHover,
    },
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: 'inherit',
    },
  },
  categoryImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 112,
    height: 112,
    backgroundColor: 'white',
    borderRadius: 2,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
    marginBottom: 1,
  },
  logo: {
    width: '36px',
    height: '36px',
    objectFit: 'contain',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '24px',
      height: '24px',
    },
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 500,
    userSelect: 'none',
  },
};
