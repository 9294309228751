import { useEffect, useMemo } from 'react';

import { useQuery } from 'react-query';

import { RESTRICTED_CROPS, getCropFamiliesData } from 'app/api/crops';
import { useAppDispatch } from 'state/hooks';
import { setCropFamilies } from 'state/slices/cropFamilies';

interface UseCropFamiliesQueryProps {
  enabled: boolean;
}

export const categoryOrder = [
  'vegetable',
  'fruit',
  'grain',
  'forage',
  'oilseed',
  'herb',
  'grass',
  'flower',
  'ornamental',
];
const categoryOrderSet = new Set(categoryOrder);

interface Family {
  name: string;
}

export const reorderCategories = <T extends Family>(crops: T[], appendMissing = false) => {
  const orderedCrops: T[] = [];
  const cropFamilies = crops.reduce((acc, crop) => {
    return { ...acc, [crop.name.toLowerCase()]: crop };
  }, {} as { [name: string]: T });
  categoryOrder.forEach((category) => {
    const family = cropFamilies[category.toLowerCase()];
    if (family) orderedCrops.push(family);
  });
  if (appendMissing) {
    crops.forEach((crop) => {
      if (!categoryOrderSet.has(crop.name.toLowerCase())) {
        orderedCrops.push(crop);
      }
    });
  }

  return orderedCrops;
};

export const useCropFamiliesQuery = ({ enabled }: UseCropFamiliesQueryProps) => {
  const {
    data,
    isLoading: isCropFamiliesLoading,
    isFetching: isCropFamiliesFetching,
    isSuccess,
  } = useQuery(['crop-families'], () => getCropFamiliesData(), {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled,
    select: (data) => {
      return data.map((family) => ({
        ...family,
        crops: family.crops.filter((crop) => RESTRICTED_CROPS.some((item) => item !== crop.name.toLowerCase())),
      }));
    },
  });

  const cropFamiliesData = useMemo(() => data?.filter((family) => !!family.crops.length), [data]) || [];
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isSuccess && data) dispatch(setCropFamilies(data));
  }, [dispatch, data, isSuccess]);

  return {
    cropFamiliesData,
    isCropFamiliesFetching,
    isCropFamiliesLoading,
  };
};
