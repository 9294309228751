import { FC } from 'react';

import { Box } from '@mui/material';
import { MessageSearch } from '@sendbird/uikit-react';
import { ClientSentMessages } from 'SendbirdUIKitGlobal';

import { SearchNoStringPlaceholder, SearchMessageItem } from './components';

interface SBSearchMessagesProps {
  channelName: string;
  channelUrl: string;
  selectedMessageId?: number;
  handleResultClick: (message: ClientSentMessages) => void;
  handleToggleSearchPannel: () => void;
}

export const SBSearchMessages: FC<SBSearchMessagesProps> = ({
  channelUrl,
  channelName,
  selectedMessageId,
  handleResultClick,
  handleToggleSearchPannel,
}) => {
  return (
    <MessageSearch
      channelUrl={channelUrl}
      renderPlaceHolderNoString={() => <SearchNoStringPlaceholder channelName={channelName} />}
      renderSearchItem={({ message, onResultClick }) => {
        const isSelected = message.messageId === selectedMessageId;

        return (
          <Box key={message.messageId}>
            <SearchMessageItem message={message} isSelected={isSelected} handleClick={onResultClick} />
          </Box>
        );
      }}
      onCloseClick={handleToggleSearchPannel}
      onResultClick={handleResultClick}
    />
  );
};
