import { FC } from 'react';

import { Box, Divider, Typography } from '@mui/material';

import { FullStatItem } from '../FullStatItem';
import { styles } from './styles';

interface FullStatsSectionProps {
  stats: { name: string; value: string }[];
}

export const FullStatsSection: FC<FullStatsSectionProps> = ({ stats }) => {
  const rowsCount = Math.ceil(stats.length / 2);

  return (
    <>
      <Typography sx={styles.title} component="h4">
        Tech Stats
      </Typography>
      <Divider sx={{ ...styles.dividerTop }} />
      <Box sx={{ ...styles.wrap(rowsCount) }}>
        {stats.map((stat) => {
          return <FullStatItem key={stat.name} {...stat} />;
        })}
      </Box>
      <Divider sx={{ ...styles.dividerBottom }} />
    </>
  );
};
