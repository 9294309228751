import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import imagePlaceholder from 'assets/images/imagePlaceholderLarge.png';
import { useImageHandlers } from 'hooks';
import { getFileExtension } from '../../utils';
import { CompanyActionsButtons } from '../CompanyActionsButtons';
import { styles } from './styles';

interface DescriptionProps {
  isCreateChannelLoading: boolean;
  isProductsList: boolean;
  hideAllButtons?: boolean;
  image?: string | null;
  description?: string;
  minOrder?: number | null;
  onRequestQuoteClick?: () => void;
  onSendMessageClick?: () => void;
}

export const Description: FC<DescriptionProps> = ({
  isCreateChannelLoading,
  isProductsList,
  image,
  description,
  minOrder = 0,
  hideAllButtons = false,
  onRequestQuoteClick,
  onSendMessageClick,
}) => {
  const { handleImageError } = useImageHandlers();
  const avatarExtension = getFileExtension(image);
  const isPdf = avatarExtension === 'pdf';

  return (
    <Box sx={{ ...styles.wrap }}>
      {!!image && !isPdf && (
        <Box
          component="img"
          loading="lazy"
          src={image}
          sx={{ ...styles.image }}
          onError={handleImageError(imagePlaceholder)}
        />
      )}
      <Box sx={{ ...styles.contentWrap }}>
        <Box sx={{ ...styles.descriptionWrap }}>
          {description && (
            <>
              <Typography component="h3" sx={{ ...styles.title }}>
                About
              </Typography>
              <Typography sx={{ ...styles.description }}>{description}</Typography>
            </>
          )}
          {!!minOrder && <Typography sx={{ ...styles.minOrder }}>Minimum Order: {minOrder}KG</Typography>}
        </Box>
        {!hideAllButtons && (
          <Box sx={styles.actionsWrap}>
            <CompanyActionsButtons
              isCreateChannelLoading={isCreateChannelLoading}
              isRequestButtons={isProductsList}
              onRequestQuoteClick={onRequestQuoteClick}
              onSendMessageClick={onSendMessageClick}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
