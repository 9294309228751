import { FC } from 'react';

import { Box, Divider } from '@mui/material';

import { ReactComponent as SellerIcon } from 'assets/icons/seller.svg';
import { ReactComponent as InventoryIcon } from 'assets/icons/inventory.svg';
import { ReactComponent as BuyerIcon } from 'assets/icons/buyer.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as CompanyProfileIcon } from 'assets/icons/companyProfile.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { LogOutModal } from 'layout/LogOutModal';
import { useDeviceType } from 'hooks';
import { UserMenuItem } from './UserMenuItem';
import { logoutItemStyles, styles } from './styles';

interface UserMenuOptionsProps {
  isBuyer: boolean;
  isModalOpen: boolean;
  isMultiRole: boolean;
  changeUser: () => void;
  handleOpenCompanyProfile: () => void;
  handleCloseModal: () => void;
  handleOpenModal: () => void;
  handleOpenChangePassword: () => void;
  handleGoToInventoryPage?: () => void;
}

export const UserMenuOptions: FC<UserMenuOptionsProps> = ({
  isBuyer,
  isModalOpen,
  isMultiRole,
  changeUser,
  handleOpenCompanyProfile,
  handleCloseModal,
  handleOpenModal,
  handleOpenChangePassword,
  handleGoToInventoryPage,
}) => {
  const { isMobile } = useDeviceType();
  return (
    <>
      {isMultiRole && (
        <UserMenuItem
          icon={isBuyer ? <SellerIcon /> : <BuyerIcon />}
          text={isBuyer ? 'Switch to Seller' : 'Switch to Buyer'}
          endIcon={
            <Box sx={{ ...styles.switchArrow }}>
              <ArrowIcon />
            </Box>
          }
          menuAction={changeUser}
        />
      )}
      {isMultiRole && <Divider sx={{ ...styles.divider }} />}

      <UserMenuItem icon={<CompanyProfileIcon />} text="Company Profile" menuAction={handleOpenCompanyProfile} />

      {!isBuyer && !isMobile && handleGoToInventoryPage && (
        <UserMenuItem icon={<InventoryIcon />} text="Inventory" menuAction={handleGoToInventoryPage} />
      )}

      <UserMenuItem icon={<LockIcon />} text="Change Password" menuAction={handleOpenChangePassword} />

      <UserMenuItem icon={<LogoutIcon />} text="Log Out" wrapStyles={logoutItemStyles} menuAction={handleOpenModal} />

      {isModalOpen && <LogOutModal title="Log Out" handleClose={handleCloseModal} isOpen={isModalOpen} />}
    </>
  );
};
