import { FC } from 'react';

import { Collapse, TableCell, TableRow, Typography } from '@mui/material';

import { Product } from 'app/api/products/types';
import { geneticTypeLabel } from 'components/ProductCard/constants';
import { capitalizeFirstLetter } from 'utils/formatText';
import { useProductRow } from '../../hooks';
import { ProductDetails } from '../ProductDetails';
import { AddProductButton } from '../AddProductButton';
import { styles } from './styles';
import { OutOfStockLabel } from '../OutOfStockLabel';

interface ProductRowProps {
  data: Product;
  choosedProductsKeys: Set<number>;
  isSampleRequest: boolean;
  setChoosedProducts: (value: Product) => void;
}

export const ProductRow: FC<ProductRowProps> = ({
  data,
  choosedProductsKeys,
  isSampleRequest,
  setChoosedProducts,
}): JSX.Element => {
  const { detailsData, isChoosed, isLoading, open, handleAddProduct, handleViewDetails } = useProductRow({
    data,
    choosedProductsKeys,
    isSampleRequest,
    setChoosedProducts,
  });

  return (
    <>
      <TableRow sx={{ ...styles.tableRow(open, data.inStock) }} onClick={handleViewDetails}>
        <TableCell sx={{ ...styles.cell }} align="left" data-testid="add-button">
          {data.inStock && <AddProductButton isChoosed={isChoosed} handleAddProduct={handleAddProduct} />}
          {!data.inStock && <OutOfStockLabel />}
        </TableCell>
        <TableCell sx={{ ...styles.cell, ...styles.typeCell }} align="left">
          {capitalizeFirstLetter(data.crop)}
        </TableCell>
        <TableCell sx={{ ...styles.cell }} align="left">
          <Typography sx={{ ...styles.labelCell }}>{data.name}</Typography>
        </TableCell>
        <TableCell sx={{ ...styles.cell }} align="left">
          <Typography sx={{ ...styles.labelCell }}>{data.variety ?? ''}</Typography>
        </TableCell>
        <TableCell sx={{ ...styles.cell, ...styles.typeCell }} align="left">
          {geneticTypeLabel[data.geneticType]}
        </TableCell>
        <TableCell sx={{ ...styles.cell }} align="left">
          <Typography sx={{ ...styles.descriptionCell }}>{data.description}</Typography>
        </TableCell>
      </TableRow>
      <TableRow data-testid="details">
        <TableCell sx={{ ...styles.productDetailsCell(open) }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ProductDetails data={detailsData} isLoading={isLoading || !detailsData} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
