import { useCallback, useMemo } from 'react';

import { useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { getCompanyData } from 'app/api/company/service';
import { CompanyData } from 'app/api/company/types';
import { Product } from 'app/api/products/types';
import { getDefaultUserRoute } from 'app/providers/utils';
import { ROUTES } from 'app/routes/constants';
import {
  useAuth,
  useChatsListQuery,
  useCreateChannelMutation,
  useDeviceType,
  useModal,
  useProductsListQuery,
} from 'hooks';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { SortTypes } from 'pages/DashboardPage/types';
import { QuoteRequestStatus } from 'app/api/quotes';
import { useQuoteRequestsQuery } from 'pages/DashboardPage/hooks/useQuoteRequestsQuery';

const PRODUCTS_COUNT_STEP = 40;
const MOBILE_PRODUCTS_COUNT_STEP = 20;

interface CompanyDetailsPageHanlers {
  channelUrl: string;
  isChatModalOpen: boolean;
  isCreateChannelLoading?: boolean;
  isLoading: boolean;
  data?: CompanyData;
  products: Product[];
  isBuyer: boolean;
  isTeamList: boolean;
  isProductsList: boolean;
  isProductsNextPageLoading: boolean;
  hasProductsNextPage: boolean;
  hideAllButtons: boolean;
  handleCloseChatModal: () => void;
  onLoadMore: () => void;
  onRequestQuoteClick: () => void;
  onSendMessageClick: () => void;
}

export const useCompanyDetailsPageHanlers = (): CompanyDetailsPageHanlers => {
  const { isBuyer, activeUser } = useAuth();
  const { isMobileSize } = useDeviceType();
  const { displayId = '' } = useParams<{ displayId: string }>();
  const navigate = useNavigate();
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();
  const {
    open: isChatModalOpen,
    handleOpenModal: handleOpenChatModal,
    handleCloseModal: handleCloseChatModal,
  } = useModal();

  const isCurrentUserCompany = displayId === activeUser?.company.displayId;
  const productsLimit = useMemo(
    () => (isMobileSize ? MOBILE_PRODUCTS_COUNT_STEP : PRODUCTS_COUNT_STEP),
    [isMobileSize],
  );

  const { isLoading, data } = useQuery(['company', displayId], () => getCompanyData(displayId), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: () => {
      navigate(getDefaultUserRoute(isBuyer));
    },
  });

  const buyerCompanyId = Number(activeUser?.company.id);
  const sellerCompanyId = Number(data?.id);

  const { chatsList, isChatsListLoading } = useChatsListQuery({
    buyerCompanyId,
    sellerCompanyId,
    enabled: isBuyer && !!buyerCompanyId && !!sellerCompanyId,
  });
  const {
    data: productsListData,
    isLoading: isProductsLoading,
    isFetchingNextPage: isProductsNextPageLoading,
    hasNextPage: hasProductsNextPage,
    fetchNextPage: fetchProductsNextPage,
  } = useProductsListQuery({
    cacheTime: 0,
    company: displayId,
    offset: 1,
    enabled: true,
    limit: productsLimit,
  });

  const { isCreateChannelLoading, handleCreateChannel } = useCreateChannelMutation({
    buyerCompany: buyerCompanyId,
    sellerCompany: sellerCompanyId,
    onSuccess: handleOpenChatModal,
  });

  const products = useMemo(
    () => productsListData?.pages?.flatMap((item) => item?.results ?? []) ?? [],
    [productsListData?.pages],
  );

  const channelUrl = useMemo(() => chatsList?.[0]?.chatId ?? '', [chatsList]);

  const hasSellerAccount = !!data?.hasSeller;

  const hideAllButtons = useMemo(
    () => !isBuyer || (isBuyer && isCurrentUserCompany && !hasSellerAccount) || !hasSellerAccount,
    [hasSellerAccount, isBuyer, isCurrentUserCompany],
  );

  const onLoadMore = useCallback(() => {
    if (!isProductsLoading && hasProductsNextPage) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchProductsNextPage();
    }
  }, [fetchProductsNextPage, hasProductsNextPage, isProductsLoading]);

  const { data: quotes } = useQuoteRequestsQuery({
    enabled: !!data?.id,
    sortValue: SortTypes.DEFAULT,
    status: [QuoteRequestStatus.OPEN],
    sellerCompanyId: data?.id,
  });

  const onRequestQuoteClick = useCallback(() => {
    const existingQuoteRequest = quotes?.find((quote) => !quote.isSampleType);
    if (existingQuoteRequest) {
      const path = generatePath(ROUTES.buyer.quoteRequestAdd, {
        companyDisplayId: displayId,
        quoteId: existingQuoteRequest.id,
      });
      navigate(path);
      handleTrackLinkClickEvent({ path, text: 'Request Quote', type: AnalyticsLinkType.BUTTON });
    } else {
      const path = generatePath(ROUTES.buyer.quoteRequestCompany, { companyDisplayId: displayId });
      navigate(path);
      handleTrackLinkClickEvent({ path, text: 'Request Quote', type: AnalyticsLinkType.BUTTON });
    }
  }, [displayId, handleTrackLinkClickEvent, navigate, quotes]);

  const onSendMessageClick = useCallback(() => {
    if (channelUrl?.length) {
      handleOpenChatModal();
    } else {
      handleCreateChannel();
    }
  }, [channelUrl?.length, handleCreateChannel, handleOpenChatModal]);

  return {
    channelUrl,
    isChatModalOpen,
    isCreateChannelLoading,
    isLoading: isLoading || isProductsLoading || isChatsListLoading,
    isProductsNextPageLoading,
    data,
    products,
    isBuyer,
    isTeamList: !!data?.teamMembers.length,
    isProductsList: !!products.length,
    hasProductsNextPage,
    hideAllButtons,
    handleCloseChatModal,
    onLoadMore,
    onRequestQuoteClick,
    onSendMessageClick,
  };
};
