import { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { ACTIVATION_TOKEN_ACCEPTED, ACTIVATION_TOKEN_EXPIRED } from '../../../../../app/api/auth/errorCodes';
import { createPassword, resetPasswordSubmit } from '../../../../../app/api/auth/service';
import { CreatePasswordData, CreatePasswordError, CreatePasswordRequest } from '../../../../../app/api/auth/types';
import { ROUTES } from '../../../../../app/routes/constants';
import { notificationObserver } from '../../../../../utils/observer';
import { inititalValues } from './initialValues';

interface CreatePasswordFormOptions {
  isResetPassword: boolean;
}

export const useCreatePasswordForm = ({ isResetPassword }: CreatePasswordFormOptions) => {
  const { search: token } = useLocation();
  const navigate = useNavigate();

  const [formError, setFormError] = useState('');

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePasswordData>({
    defaultValues: inititalValues,
  });

  const { mutate: createPasswordMutation, isLoading } = useMutation(
    (data: CreatePasswordRequest) => (isResetPassword ? resetPasswordSubmit(data) : createPassword(data)),
    {
      onSuccess: () => {
        navigate(ROUTES.buyer._);

        if (isResetPassword) {
          notificationObserver.publish({
            type: 'success',
            title: 'Your password has been changed',
          });
        }
      },
      onError: (error: AxiosError) => {
        const errData = error?.response?.data as CreatePasswordError;

        if (errData?.token?.code === ACTIVATION_TOKEN_EXPIRED || errData?.token?.code === ACTIVATION_TOKEN_ACCEPTED) {
          notificationObserver.publish({
            type: 'error',
            title: 'Your link is expired',
          });
        } else if (errData?.password) {
          notificationObserver.publish({
            type: 'error',
            title: errData?.password?.message,
          });
        } else if (error?.message) {
          notificationObserver.publish({
            type: 'error',
            title: error.message,
          });
        }
      },
    },
  );

  const onSubmit = (values: CreatePasswordData) => {
    const { password, newPassword } = values;

    if (password !== newPassword) {
      setFormError('Password do not match. Please try again.');
      return;
    }

    createPasswordMutation({
      password,
      token: token?.substring(1) || '',
    });
  };

  const onChange = () => {
    if (formError) {
      setFormError('');
    }
  };

  return {
    errors,
    control,
    onChange,
    register,
    onSubmit,
    formError,
    handleSubmit,
    isLoading,
  };
};
