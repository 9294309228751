import { FC } from 'react';

import { Box } from '@mui/material';
import { ClientSentMessages } from 'SendbirdUIKitGlobal';

import { SBChatMode } from 'app/api/SBChat';
import { SBConversation } from 'components';
import { useCompanyChatHandlers } from 'hooks';
import { ChatHeader } from '../ChatHeader';
import { SelectedChatEmptyState } from '../SelectedChatEmptyState';
import { styles } from './styles';

interface MessagesPageChatProp {
  channelName: string;
  channelUrl: string | null;
  isChannelExisting: boolean;
  isLogoPlaceholder: boolean;
  isSearchMessagesPannel: boolean;
  channelLogo?: string;
  selectedMessage?: ClientSentMessages;
  startingPoint?: number;
  handleResetSelectedMessage: () => void;
  handleSearchButtonClick: () => void;
  handleBackButtonClick?: () => void;
  handleInfoButtonClick?: () => void;
}

export const MessagesPageChat: FC<MessagesPageChatProp> = ({
  channelName,
  channelUrl,
  isChannelExisting,
  isLogoPlaceholder,
  isSearchMessagesPannel,
  channelLogo = '',
  selectedMessage,
  startingPoint,
  handleResetSelectedMessage,
  handleSearchButtonClick,
  handleBackButtonClick,
  handleInfoButtonClick,
}) => {
  const {
    control,
    isLoading: isSendMessageLoading,
    isMessageData,
    handleClearMessageInput,
    handleConfirm,
    handleSubmit,
  } = useCompanyChatHandlers({ channel: channelUrl ?? '' });

  return (
    <>
      {!!channelUrl && isChannelExisting ? (
        <Box sx={styles.wrap}>
          <ChatHeader
            channelName={channelName}
            channelLogo={channelLogo}
            isLogoPlaceholder={isLogoPlaceholder}
            handleSearchButtonClick={handleSearchButtonClick}
            handleBackButtonClick={handleBackButtonClick}
            handleInfoButtonClick={handleInfoButtonClick}
          />
          <SBConversation
            mode={SBChatMode.MESSAGES_PAGE}
            channelUrl={channelUrl}
            control={control}
            disableSendButton={!isMessageData}
            isSearchMessagesPannel={isSearchMessagesPannel}
            isSendMessageLoading={isSendMessageLoading}
            selectedMessage={selectedMessage}
            startingPoint={startingPoint}
            handleClearMessageInput={handleClearMessageInput}
            handleResetSelectedMessage={handleResetSelectedMessage}
            handleSendMessage={handleSubmit(handleConfirm)}
          />
        </Box>
      ) : (
        <SelectedChatEmptyState />
      )}
    </>
  );
};
