import { Input } from '@mui/base/Input';

import { FC, forwardRef } from 'react';
import { Box, IconButton, Popper, useForkRef } from '@mui/material';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/searchClose.svg';
import { useSearch } from 'layout/HeaderSearch';

import { CommonButton } from 'components';
import { useAuth } from 'hooks';
import { useAppDispatch } from 'state/hooks';
import { setSignupModal } from 'state/slices/account';

import { styles } from './styles';

export const SearchBar: FC = forwardRef((_, ref) => {
  const { activeUser } = useAuth();
  const hasActiveUser = !!activeUser;
  const { query, clearInput, autocompleteResults, autocomplete, onClickSearchIcon, inputProps, hasItems } = useSearch();
  const rootRef = useForkRef(ref, autocomplete.setAnchorEl);
  const dispatch = useAppDispatch();

  const onRequestJoin = () => {
    dispatch(setSignupModal({ isOpen: true, state: 'requestAccess', source: 'search' }));
  };

  return (
    <Box component="div" sx={{ ...styles.container }}>
      <Box component="div" ref={rootRef} {...autocomplete.getRootProps()} sx={{ ...styles.inputContainer }}>
        <Box sx={{ ...styles.searchIcon }}>
          <SearchIcon onClick={onClickSearchIcon} />
        </Box>

        <Input id={autocomplete.id} slotProps={{ input: inputProps }} placeholder="Search thousands of products..." />
        {!!query && (
          <IconButton sx={styles.closeButton(hasActiveUser)} onClick={clearInput}>
            <CloseIcon height={24} width={24} />
          </IconButton>
        )}
        {!hasActiveUser && (
          <CommonButton sx={styles.requestAccessBtn} onClick={onRequestJoin}>
            Request Free Access
          </CommonButton>
        )}
      </Box>
      {autocomplete.popupOpen && hasItems ? (
        <Popper
          open={autocomplete.popupOpen}
          anchorEl={autocomplete.anchorEl}
          disablePortal
          sx={styles.popper}
          placement="bottom-start"
          modifiers={[
            { name: 'flip', enabled: false },
            { name: 'preventOverflow', enabled: false },
          ]}
        >
          <Box component="ul" {...autocomplete.getListboxProps()} sx={styles.resultsList}>
            {(autocompleteResults?.crops ?? []).map((option, index) => (
              <Box
                component="li"
                key={option.name}
                sx={styles.result}
                {...autocomplete.getOptionProps({ option, index })}
              >
                {option.name}
              </Box>
            ))}
          </Box>
        </Popper>
      ) : null}
    </Box>
  );
});
