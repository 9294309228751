import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    position: 'relative',
    margin: '0px auto ',
    maxWidth: '530px',
    width: '100%',
    marginBottom: '32px',
  },
  inputContainer: {
    position: 'relative',
    zIndex: 2,
    '.base-Input-input': {
      fontFamily: customTheme.typography.allVariants,
      boxSizing: 'border-box',
      outline: 'unset',
      paddingBlock: 'unset',
      paddingInline: 'unset',

      padding: '5px 132px 5px 48px',
      borderRadius: '25px',

      width: '100%',
      height: '56px',
      border: `1px solid ${customTheme.custom.black.B10}`,
      fontSize: '14px',
      color: customTheme.custom.black.B100,

      '::placeholder': {
        color: customTheme.custom.black.B50,
        opacity: 1,
      },
    },
  },

  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: '16px',
    top: 0,
    bottom: 0,
    color: 'rgb(37, 39, 40)',
    svg: {
      height: '24px',
      width: '24px',
    },
    [`@media (min-width: ${breakpoints.tablet}px)`]: {
      ':hover': {
        color: customTheme.custom.black.B50,
      },
    },
  },
  requestAccessBtn: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '4px',
    top: '4px',
    bottom: '4px',
    color: customTheme.custom.white,
    backgroundColor: customTheme.custom.bannerBlue,
    height: 'calc(100% - 8px)',
    [`@media (min-width: ${breakpoints.tablet}px)`]: {
      ':hover': {
        color: customTheme.custom.white,
      },
    },
  },
  closeButton: (hasActiveUser: boolean) => ({
    position: 'absolute',
    right: '160px',
    ...(hasActiveUser ? { right: '8px' } : {}),
    top: '4px',
    bottom: '4px',
    color: customTheme.custom.neutralLight,
    height: '48px',
    width: '48px',
  }),
  popper: {
    width: '100%',
    animation: `${customTheme.animations.fadeIn} 0.1s ease-in-out`,
    zIndex: 1,
  },
  resultsList: {
    animation: `${customTheme.animations.height} 0.1s ease-in-out`,
    listStyleType: 'none',
    margin: 0,
    marginTop: '-20px',
    padding: '28px 8px 8px',
    boxSizing: 'border-box',
    backgroundColor: customTheme.custom.white,
    border: `1px solid ${customTheme.custom.black.B5}`,
    borderRadius: '4px',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
  },
  result: {
    padding: '4px 8px',
    borderRadius: '4px',
    transition: 'all 0.3s ease-in-out',
    fontWeight: 500,
    fontSize: '14px',
    color: customTheme.custom.black.B70,

    ':hover': {
      backgroundColor: customTheme.custom.black.B10,
      cursor: 'pointer',
    },

    '&[aria-selected=true]': {
      backgroundColor: customTheme.custom.black.B10,
    },
    '&.Mui-focused, &.Mui-focusVisible': {
      backgroundColor: customTheme.custom.black.B10,
    },
  },
};
