import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginBottom: '16px',
    padding: '12px 0',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '8px',
      marginBottom: '8px',
    },
  },
  image: {
    width: '80px',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '64px',
    },
  },
  nameWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '4px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: 0,
    },
  },
  name: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  location: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.custom.black.B100,
  },
  tagsWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    marginBottom: '16px',
  },
  tag: {
    padding: '6px',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B70,
    backgroundColor: customTheme.custom.black.B10,
    letterSpacing: '0.2px',
    borderRadius: '3px',
  },
};
