import { createContext, FC, ReactNode, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useMutation, UseMutationResult, useQuery } from 'react-query';
import * as tracker from 'analytics/tracker';
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';
import * as Sentry from '@sentry/react';

import { AUTH_PAGES } from 'app/api';
import { logIn, logOut } from 'app/api/auth/service';
import { LoginResponse, LoginData, UserRole, LogOutRequest } from 'app/api/auth/types';
import { PayloadError } from 'app/common/types';
import { getUserData } from 'app/api/user/service';
import { UserData } from 'app/api/user/types';
import { useIsAuthRoute, useMaintenanceMode, useSendBirdWrap } from 'hooks';
import { AnalyticsName, useTrackPageViewedEvent } from 'analytics';
import { sessionObserver } from 'utils/observer';
import {
  storage,
  removeSession,
  getAccessInfo,
  updateSession,
  updateSessionToken,
  setSendbirdUserId,
  clearSendbirdConfig,
  setSendbirdToken,
  clearLoginPath,
} from 'utils/storage';
import { createChatToken } from 'app/api/SBChat';
import { useAppDispatch } from 'state/hooks';
import { setUserSignedIn } from 'state/slices/account';

import { ROUTES } from '../routes/constants';
import { getDefaultUserRoute } from './utils';

export const AuthContext = createContext<IAuth>({} as IAuth);

export type LoginMutation = UseMutationResult<LoginResponse, unknown, LoginData>;

export type LogoutMutation = UseMutationResult<unknown, PayloadError, LogOutRequest>;

interface IAuth {
  token: string | null;
  activeUser: UserData | null;
  sendbirdUserId: string;
  activeUserCompanyId: string;
  isMultiRole: boolean;
  isBuyer: boolean;
  login: LoginMutation;
  logout: () => void;
  isLoginLoading: boolean;
  signOut: LogoutMutation;
  setUserData: (userData: UserData[]) => void;
  changeActiveUser: () => void;
  users: UserData[];
  clearUserData: () => void;
}

interface ProviderProps {
  children: ReactNode;
}

const environment = process.env.REACT_APP_ENV;

const Loading = () => <div />;

export const AuthProvider: FC<ProviderProps> = ({ children }): JSX.Element => {
  const dispatch = useAppDispatch();
  const [token, setToken] = useState<string | null>(storage.getItem('token'));
  const [activeUserRole, setActiveUserRole] = useState<string | null>(storage.getItem('userRole'));
  const [activeUser, setActiveUser] = useState<UserData | null>(null);
  const [users, setUsers] = useState<UserData[]>([]);

  const { setSbToken, setSbUserId } = useSendBirdWrap();
  const globalSBStore = useSendbirdStateContext();
  const disconnectSB = sendbirdSelectors.getDisconnect(globalSBStore);

  const { isAuthRoute } = useIsAuthRoute();

  const navigate = useNavigate();
  const location = useLocation();

  // Analytics for each new page route view
  useTrackPageViewedEvent();

  useMaintenanceMode();

  const sendbirdUserId = useMemo(() => activeUser?.user.chatId ?? '', [activeUser?.user.chatId]);
  const activeUserCompanyId = useMemo(() => activeUser?.company.id.toString() ?? '', [activeUser?.company.id]);
  const isBuyer = useMemo(() => activeUserRole === UserRole.BUYER, [activeUserRole]);

  const getUser = useQuery(['user-data'], () => getUserData(), {
    enabled: false,
  });

  const getChatToken = useQuery(['sendbird-token'], () => createChatToken(), {
    enabled: false,
  });

  const login = useMutation((userData: LoginData) => logIn(userData), {
    onSuccess: async (data) => {
      updateSessionToken(data.token);
      await getUser.refetch().then(({ data }) => {
        if (data) {
          setUserData(data);
          tracker.track(AnalyticsName.USER_LOGGED_IN);
        }
        return data;
      });

      await getChatToken.refetch().then(({ data }) => {
        setSendbirdToken(data?.token ?? '');
      });

      if (!isBuyer) {
        navigate(getDefaultUserRoute(isBuyer));
      }
    },
  });

  const signOut = useMutation<unknown, PayloadError, LogOutRequest>(({ token }) => logOut(token), {
    onSuccess: () => {
      removeSession();
      setToken(null);
      setActiveUserRole(null);
      setSbToken(null);
      setSbUserId(null);
      setUsers([]);
      clearLoginPath();
      dispatch(setUserSignedIn({ isSignedIn: false, user: null }));

      disconnectSB()
        .then(() => {
          /* empty */
        })
        .catch(() => {
          /* empty */
        });

      tracker.reset();
      tracker.track(AnalyticsName.USER_LOGGED_OUT);
      navigate(ROUTES.buyer._, { replace: true });
      window.location.reload();
    },
  });

  const logout = useCallback(() => {
    const token = getAccessInfo('token');

    if (token) {
      clearSendbirdConfig();
      signOut.mutate({ token });
    } else {
      setToken(null);
      setActiveUserRole(null);
      setSbToken(null);
      setSbUserId(null);
      setUsers([]);
      dispatch(setUserSignedIn({ isSignedIn: false, user: null }));
    }
  }, [signOut, setSbToken, setSbUserId, dispatch]);

  const logoutHandler = useCallback(
    (isAuth: boolean) => {
      if (!isAuth && !isAuthRoute) {
        logout();
      }
    },
    [isAuthRoute, logout],
  );

  const setUserData = useCallback(
    (userData: UserData[]) => {
      if (!userData) {
        dispatch(setUserSignedIn({ isSignedIn: false, user: null }));
        return;
      }

      setUsers(userData);

      let activeUserSet = null;
      if (!activeUserRole || !userData.find((user) => user.kind === activeUserRole)) {
        const user =
          userData?.length === 1 ? userData[0] : userData.find((user) => user.kind === UserRole.SELLER) || userData[0];

        updateSession(user);
        setSendbirdUserId(user?.user.chatId);
        setSbUserId(user?.user.chatId);
        setToken(storage.getItem('token'));
        setSbToken(storage.getItem('sendbirdToken'));
        setActiveUserRole(user?.kind);
        setActiveUser(user);
        dispatch(setUserSignedIn({ isSignedIn: true, user }));
        activeUserSet = user;
      }

      if (activeUserRole) {
        const user = userData.find((user) => user.kind === activeUserRole);

        if (user) {
          updateSession(user);
          setSendbirdUserId(user.user.chatId);
          setSbUserId(user.user.chatId);
          setActiveUserRole(user.kind);
          setActiveUser(user);
          dispatch(setUserSignedIn({ isSignedIn: true, user }));
          activeUserSet = user;
        }
      }
      if (activeUserSet) {
        const traits = {
          firstName: activeUserSet.user.firstName,
          lastName: activeUserSet.user.lastName,
          email: activeUserSet.user.email,
          kind: activeUserSet.kind,
          companyName: activeUserSet.company.name,
          companyId: activeUserSet.company.id,
        };
        tracker.identify(activeUserSet.user.id.toString(), traits);
      }
    },
    [activeUserRole, setSbToken, setSbUserId, dispatch],
  );

  const changeActiveUser = useCallback(() => {
    const user = users.find((user) => user.kind !== activeUserRole) || activeUser;

    if (user) {
      updateSession(user);
      setSendbirdUserId(user.user.chatId);
      setSbUserId(user.user.chatId);
      setActiveUserRole(user.kind);
      setActiveUser(user);
      navigate(getDefaultUserRoute(isBuyer));
    }
  }, [activeUserRole, isBuyer, setActiveUserRole, navigate, users, activeUser, setSbUserId]);

  const clearUserData = useCallback(() => {
    removeSession();
    setActiveUserRole(null);
    setUsers([]);
  }, []);

  const isLoginLoading = useMemo(() => login.isLoading || getUser.isLoading, [login, getUser]);
  const isMultiRole = useMemo(() => users?.length > 1, [users]);

  useEffect(() => {
    sessionObserver.subscribe(logoutHandler);

    return () => sessionObserver.unsubscribe(logoutHandler);
  }, [logoutHandler]);

  useEffect(() => {
    if (token && AUTH_PAGES.includes(location.pathname) && location.pathname !== ROUTES.auth.login) {
      navigate(getDefaultUserRoute(isBuyer));
    }
  }, [token, location, navigate, isBuyer]);

  useEffect(() => {
    Sentry.setUser({ id: activeUser?.id });
  }, [activeUser?.id]);

  const value = useMemo(
    () => ({
      token,
      activeUser,
      sendbirdUserId,
      activeUserCompanyId,
      isMultiRole,
      isBuyer,
      login,
      logout,
      isLoginLoading,
      signOut,
      setUserData,
      changeActiveUser,
      users,
      clearUserData,
    }),
    [
      token,
      activeUser,
      sendbirdUserId,
      activeUserCompanyId,
      login,
      logout,
      isLoginLoading,
      signOut,
      setUserData,
      changeActiveUser,
      isMultiRole,
      isBuyer,
      users,
      clearUserData,
    ],
  );
  const isAnonymousUser = useMemo(
    () => !isLoginLoading && !activeUser && getUser.isFetched,
    [isLoginLoading, activeUser, getUser],
  );
  return (
    <Suspense fallback={<Loading />}>
      <AuthContext.Provider value={value}>
        <Helmet>
          {isAnonymousUser && environment === 'production' && (
            <script
              id="vtag-ai-js"
              async
              src="https://r2.leadsy.ai/tag.js"
              data-pid="1mGoZkvSkwLI2MoKF"
              data-version="062024"
            />
          )}
        </Helmet>
        {children}
        <Outlet />
      </AuthContext.Provider>
    </Suspense>
  );
};
