import { FC } from 'react';

import { Avatar, Box, Typography } from '@mui/material';

import { TeamMember } from 'app/api/company/types';
import avatarPlaceholder from 'assets/images/teamMemberPlaceholder.png';
import { getInitials } from 'utils/userName';
import { getFileExtension } from '../../utils';
import { styles } from './styles';

type AvatarCardProps = Omit<TeamMember, 'id'>;

export const AvatarCard: FC<AvatarCardProps> = ({ name, role, photo }) => {
  const avatarExtension = getFileExtension(photo);
  const src = avatarExtension === 'pdf' || !photo ? avatarPlaceholder : photo;

  return (
    <Box sx={{ ...styles.wrap }}>
      <Avatar alt={name} src={src} sx={{ ...styles.avatar }}>
        {getInitials(name)}
      </Avatar>
      <Box>
        <Typography sx={{ ...styles.role }}>{role}</Typography>
        <Typography sx={{ ...styles.name }}>{name}</Typography>
      </Box>
    </Box>
  );
};
