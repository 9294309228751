import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  cell: {
    width: '125px',
    padding: '16px',
    borderBottom: `1px solid ${customTheme.custom.black.B10}`,
    color: customTheme.custom.black.B70,

    ':first-of-type': {
      padding: '16px 12px 16px 24px',
      width: '60px',
      maxWidth: '60px',
    },

    '&:last-child': {
      padding: '16px 24px 16px 12px',
      width: '295px',
    },
  },
  tableRow: (open: boolean, inStock: boolean) => ({
    height: '72px',

    ':nth-last-of-type(-n+2)': {
      td: {
        border: 0,
      },
    },

    ':hover': {
      backgroundColor: customTheme.palette.primary.P5,
      cursor: 'pointer',
    },

    ...(open && {
      backgroundColor: customTheme.custom.blue.B50,

      ':hover': {
        backgroundColor: customTheme.custom.blue.B50,
        cursor: 'pointer',
      },
    }),

    ...(!inStock && {
      backgroundColor: customTheme.custom.gray.G50,

      ':hover': {
        backgroundColor: customTheme.custom.gray.G50,
        cursor: 'pointer',
      },
    }),
  }),
  labelCell: {
    fontSize: '12px',
    lineHeight: '18px',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  typeCell: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '18px',
  },
  descriptionCell: {
    fontSize: '10px',
    lineHeight: '10px',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  productDetailsCell: (open: boolean) => ({
    padding: 0,
    borderBottom: open ? `1px solid ${customTheme.custom.black.B10}` : 'none',
  }),
};
