import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginBottom: '40px',
    display: 'grid',
    gap: '24px',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: 0,
      gap: '14px',
      gridTemplateColumns: '1fr',
    },
  },
  contentWrap: {
    flex: '1 1 0',
  },
  descriptionWrap: {
    position: 'relative',
    marginBottom: '24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '14px',
    },
  },
  title: {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '4px',
      fontSize: '14px',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.gray.G600,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '12px',
    },
  },
  image: {
    maxHeight: '464px',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '25px',
  },
  minOrder: {
    position: 'absolute',
    right: '16px',
    bottom: '-16px',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.palette.secondary.S100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      position: 'static',
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  actionsWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
};
