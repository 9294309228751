import { FC, useEffect } from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { PageLoader } from 'components';
import { useAuth } from 'hooks';
import { Footer } from 'layout/Footer';
import { MobileHeader, MobileSideBar } from './components';
import { useMobilePageLayout } from './hooks';
import { styles } from './styles';

interface MobilePageLayoutProps {
  isLoading: boolean;
}

export const MobilePageLayout: FC<MobilePageLayoutProps> = ({ isLoading }) => {
  const { isBuyer, activeUser } = useAuth();
  const { isFooterVisible, isSideBarOpen, handleSideBarClose, handleSideBarToggle } = useMobilePageLayout();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Box sx={styles.wrap}>
        {isLoading ? (
          <Box sx={styles.loaderWrap}>
            <PageLoader />
          </Box>
        ) : (
          <>
            <MobileHeader isShadow onMenuButtonClick={handleSideBarToggle} />
            <Box sx={styles.contentWrap(isBuyer || !activeUser)}>
              <Box sx={{ ...styles.content }}>
                <Outlet />
              </Box>
            </Box>
            {isFooterVisible && <Footer />}
          </>
        )}
      </Box>
      <MobileSideBar isOpen={isSideBarOpen} onClose={handleSideBarClose} onToggle={handleSideBarToggle} />
    </>
  );
};
