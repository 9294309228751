import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { Box, IconButton, Typography } from '@mui/material';

import { Quote, QuoteProduct, QuoteProductStatus, UnitsType } from 'app/api/quotes';
import { ReactComponent as CloseIcon } from 'assets/icons/searchClose.svg';
import { EditButton } from 'components';
import { useAuth, useModal } from 'hooks';
import { QuoteTableFormData } from 'pages/DashboardPage/types';
import { PRODUCT_IS_NO_LONGER_AVAILABLE, PRODUCT_REMOVED, QUANTITY_NOT_AVAILABLE } from 'pages/DashboardPage/constants';
import {
  ProductVariety,
  SellerActionsMenu,
  RemoveProductPopup,
  RemoveLastProductPopup,
  SellerInputNeededLabel,
  CounterOfferCell,
  ConfirmActions,
} from 'pages/DashboardPage/components';
import { useQuoteDiscussionPopups, useQuoteDiscussionRow, useSellersActions } from 'pages/DashboardPage/hooks';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { cardItemTitles } from 'pages/QuoteDetailsPage/constants';
import { MobileSelect } from 'components/MobileSelect';
import { formatNumber } from 'utils/formatText';
import { currencyFormat } from 'utils/currencyFormat';
import { CellHeader } from '../CellHeader';
import { styles } from './styles';
import { ChangeUnitType } from '../ChangeUnitType';
import { OutOfStock } from '../OutOfStock';

const getIsAmountEditable = (isDisabled: boolean, quoteProductStatus: QuoteProductStatus | undefined) =>
  !isDisabled &&
  (quoteProductStatus === QuoteProductStatus.AVAILABLE || quoteProductStatus === QuoteProductStatus.NOT_AVAILABLE);

const getIsConfirmActions = (isRemoved: boolean, isUnavailable: boolean, isRestricted: boolean) =>
  !isRemoved && !isUnavailable && !isRestricted;

interface QuoteDiscussionCardProps {
  data: QuoteProduct;
  unitsType: UnitsType;
  control: Control<QuoteTableFormData>;
  index: number;
  isWaiting: boolean;
  productsCount: number;
  quoteId: number;
  isDisabled: boolean;
  isCurrentProductRemoveModal: boolean;
  quoteData: Quote;
  handleOpenEditAmountModal: (id: number) => void;
  removeChoosedProducts: (index: number) => void;
  setValue: UseFormSetValue<QuoteTableFormData>;
  increaseConfirmedProducsCount: () => void;
  decreaseConfirmedProducsCount: () => void;
  closeDetails: () => void;
  handleCloseEditAmountModal: () => void;
  handleNavigateToDashboard: () => void;
  setCurrentRemoveId: (id: number | null) => void;
  isEditMode: boolean;
}

export const QuoteDiscussionCard: FC<QuoteDiscussionCardProps> = ({
  data,
  unitsType,
  control,
  index,
  isWaiting,
  productsCount,
  quoteId,
  isDisabled,
  isCurrentProductRemoveModal,
  isEditMode,
  handleOpenEditAmountModal,
  removeChoosedProducts,
  setValue,
  increaseConfirmedProducsCount,
  decreaseConfirmedProducsCount,
  closeDetails,
  handleCloseEditAmountModal,
  handleNavigateToDashboard,
  setCurrentRemoveId,
  quoteData,
}): JSX.Element => {
  const { isBuyer } = useAuth();
  const { isSampleType: isSampleRequest, priceUnits } = quoteData;
  const {
    isModalOpen,
    isLastModalOpen,
    handleOpenRemoveProductModal,
    handleLastProductOpen,
    handleEditAmountButtonClick,
    handleCloseRemovePopup,
    handleCloseRemoveLastPopup,
  } = useQuoteDiscussionPopups({ id: data.id, handleOpenEditAmountModal, setCurrentRemoveId });

  const {
    open: isEditUnitsModalOpen,
    handleOpenModal: handleOpenEditUnitsModal,
    handleCloseModal: handleCloseEditUnitsModal,
  } = useModal();

  const isNotlastProduct = useMemo(() => {
    return productsCount > 1;
  }, [productsCount]);

  const quoteProductStatus = data.status;
  const isAmountEditable = getIsAmountEditable(isDisabled, quoteProductStatus);

  const {
    quotePrice,
    isLoading,
    lastOffer,
    totalPrice,
    isConfirm,
    isActive,
    isRemoved,
    isUnavailable,
    isRestricted,
    isOpacity,
    isSellerInputNeeded,
    counterOfferPrice,
    isCancelLoading,
    handleRemoveRequest,
    handleConfirm,
    handleUndo,
    handleCancel,
  } = useQuoteDiscussionRow({
    control,
    data,
    index,
    isNotlastProduct,
    quoteId,
    isSampleRequest,
    priceUnits,
    closeDetails,
    decreaseConfirmedProducsCount,
    handleCloseEditAmountModal,
    handleCloseModal: handleCloseRemovePopup,
    increaseConfirmedProducsCount,
    removeChoosedProducts,
    setValue,
    handleNavigate: handleNavigateToDashboard,
  });

  const [myLastOffer, myLastOfferCurrency] = useMemo(() => {
    if (isBuyer && data.latestBuyerPrice) return [data.latestBuyerPrice.price, data.latestBuyerPrice.priceUnits];
    if (!isBuyer && data.latestSellerPrice) return [data.latestSellerPrice.price, data.latestSellerPrice.priceUnits];
    return [undefined, priceUnits];
  }, [data, isBuyer, priceUnits]);

  const { open, anchorEl, handleClick, handleClose, handleRemove, handleMarkNotAvailable, handleStatusUndo } =
    useSellersActions({
      index,
      isConfirm,
      setValue,
      handleUndo,
    });

  const isConfirmActions = isEditMode && getIsConfirmActions(isRemoved, isUnavailable, isRestricted);
  const unavailableProductLabel = isUnavailable ? QUANTITY_NOT_AVAILABLE : PRODUCT_IS_NO_LONGER_AVAILABLE;
  const isThousandSeedsUnit = unitsType === UnitsType.THOUSAND_SEEDS;
  const titles = cardItemTitles(isBuyer);
  const isConfirmButton =
    !isRemoved && !isUnavailable && !isRestricted && !!counterOfferPrice && !quotePrice && !data.acceptedPrice;

  const handleRemoveModalOpen = useMemo(() => {
    return isNotlastProduct ? handleOpenRemoveProductModal : handleLastProductOpen;
  }, [isNotlastProduct, handleOpenRemoveProductModal, handleLastProductOpen]);

  useEffect(() => {
    if (isCurrentProductRemoveModal) handleRemoveModalOpen();
  }, [handleRemoveModalOpen, isCurrentProductRemoveModal]);

  const {
    open: isEditUnitsValueModalOpen,
    handleOpenModal: handleOpenEditUnitsValueModal,
    handleCloseModal: handleCloseEditUnitsValueModal,
  } = useModal();

  const {
    open: isOutOfStockModalOpen,
    handleOpenModal: handleOpenOutOfStockModal,
    handleCloseModal: handleCloseOutOfStockModal,
  } = useModal();

  const [updatedQuantityUnit, setUpdatedQuantityUnit] = useState<UnitsType | null>(null);

  const onChangeUnitType = (amountValue: number) => {
    setValue(`quotes.${index}.amount`, amountValue);
    setValue(`quotes.${index}.amountType`, updatedQuantityUnit as UnitsType);
    setUpdatedQuantityUnit(null);
  };

  const quantityAmount = useWatch({ control, name: `quotes.${index}.amount` });
  const quantityUnit = useWatch({ control, name: `quotes.${index}.amountType` });
  const setQuantityUnits = useCallback(
    (value: string) => {
      if ([quantityUnit, value].some((item) => item === UnitsType.THOUSAND_SEEDS)) {
        setUpdatedQuantityUnit(value as UnitsType);
        handleOpenEditUnitsValueModal();
      } else {
        const multiplier = quantityUnit === UnitsType.KG ? 2.20462 : 0.453592;
        setValue(`quotes.${index}.amount`, Math.round(quantityAmount * multiplier));
        setValue(`quotes.${index}.amountType`, value as UnitsType);
      }
      // If the units change, reset the accepted price
      setValue(`quotes.${index}.acceptedPrice`, 0);
    },
    [index, setValue, quantityUnit, quantityAmount, handleOpenEditUnitsValueModal, setUpdatedQuantityUnit],
  );

  const setQuantityAmount = useCallback(
    (quantityAmount: number) => {
      setValue(`quotes.${index}.amount`, quantityAmount);
      // If the quantity amount changes, reset the accepted price
      setValue(`quotes.${index}.acceptedPrice`, 0);
    },
    [index, setValue],
  );

  return (
    <Box sx={styles.wrap}>
      <Box sx={{ ...styles.varietyWrap(isRestricted), ...styles.opacityWrap(isOpacity) }}>
        <ProductVariety data={data} fullWidth isThousandSeedsUnit={isThousandSeedsUnit} />
      </Box>

      <Box sx={styles.row}>
        <Box sx={styles.gridItemWrap}>
          <Box sx={styles.opacityWrap(isOpacity)}>
            <CellHeader {...titles.amount} isInline={isThousandSeedsUnit} />
          </Box>

          <Box sx={styles.weightInner}>
            <Box component="span" sx={{ ...styles.amountWeight, ...styles.opacityWrap(isOpacity) }}>
              {formatNumber(quantityAmount)}
            </Box>
            {isAmountEditable && (
              <Box>
                <EditButton onClick={handleEditAmountButtonClick} />
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={styles.gridItemWrap}>
          <Box sx={styles.opacityWrap(isOpacity)}>
            <CellHeader {...titles.units} isInline={isThousandSeedsUnit} />
          </Box>

          <Box sx={styles.weightInner}>
            <Box component="span" sx={{ ...styles.amountWeight, ...styles.opacityWrap(isOpacity) }}>
              {getUnitDisplayValue(quantityUnit, true)}
            </Box>
            {isAmountEditable && (
              <>
                <Box>
                  <EditButton onClick={handleOpenEditUnitsModal} />
                </Box>
                <MobileSelect
                  isOpen={isEditUnitsModalOpen}
                  handleClose={handleCloseEditUnitsModal}
                  title="Change Quantity Units"
                  value={quantityUnit}
                  onChange={setQuantityUnits}
                  options={[
                    { label: 'kilograms', abbr: 'kgs', value: UnitsType.KG },
                    { label: 'pounds', abbr: 'lbs', value: UnitsType.LB },
                    { label: 'thousand seeds', value: UnitsType.THOUSAND_SEEDS },
                  ]}
                />
                <ChangeUnitType
                  isOpen={isEditUnitsValueModalOpen}
                  handleClose={handleCloseEditUnitsValueModal}
                  onChange={onChangeUnitType}
                  data={data}
                  amount={data.amount}
                  currentUnitType={quantityUnit}
                  updatedUnitType={updatedQuantityUnit as UnitsType}
                />
              </>
            )}
          </Box>
        </Box>
        <Box sx={{ ...styles.gridItemWrap, ...styles.opacityWrap(isOpacity) }}>
          <CellHeader {...titles.latestOfferSubtotals} />
          <Box sx={styles.lastOfferSubtotal(!!totalPrice && !isOpacity)}>{totalPrice}</Box>
        </Box>
        {myLastOffer && myLastOfferCurrency && (
          <Box sx={{ ...styles.gridItemWrap, ...styles.opacityWrap(isOpacity) }}>
            <CellHeader {...titles.myLastOfferPrice} isInline={isThousandSeedsUnit} />
            <Box sx={styles.latestOfferPrice}>{currencyFormat(myLastOffer, myLastOfferCurrency)}</Box>
          </Box>
        )}
        {lastOffer && (
          <Box sx={{ ...styles.gridItemWrap, ...styles.opacityWrap(isOpacity) }}>
            <CellHeader {...titles.latestOfferPrice} isInline={isThousandSeedsUnit} />
            <Box sx={styles.latestOfferPrice}>
              {isSellerInputNeeded ? <SellerInputNeededLabel /> : lastOffer || '-'}
            </Box>
          </Box>
        )}
        {isConfirmActions && (
          <Box sx={{ ...styles.gridItemWrap, ...styles.actions(!!isConfirm) }}>
            {isConfirmButton && (
              <ConfirmActions
                isConfirm={!!isConfirm}
                isDisabled={isDisabled}
                isThousandSeeds={isThousandSeedsUnit}
                handleConfirm={handleConfirm}
                handleUndo={handleUndo}
              />
            )}
          </Box>
        )}
        {!isBuyer && (isRemoved || isUnavailable) && (
          <Box sx={{ ...styles.gridItemWrap }}>
            <Box sx={{ ...styles.undoLabel }} onClick={handleStatusUndo}>
              Undo
            </Box>
          </Box>
        )}
      </Box>

      <Box sx={styles.bottomActionsWrap}>
        <Box sx={styles.counterOfferCell(!!isConfirmButton && !isConfirm)}>
          {(isEditMode || !isActive) && (
            <Box sx={styles.opacityWrap(isOpacity)}>
              <CellHeader {...titles.counterOfferPrice} isInline />
            </Box>
          )}
          {isEditMode && (
            <CounterOfferCell
              control={control}
              index={index}
              isConfirm={!!data.acceptedPrice || !!isConfirm}
              isDisabled={isDisabled}
            />
          )}
          {!isActive && (
            <Box sx={styles.opacityWrap(isOpacity)}>
              <Typography sx={styles.statusLabel}>{isRemoved ? PRODUCT_REMOVED : unavailableProductLabel}</Typography>
            </Box>
          )}
        </Box>
        {!isBuyer && isEditMode && !isRemoved && !isUnavailable && (
          <>
            <Box sx={styles.outOfStockContainer} onClick={handleOpenOutOfStockModal}>
              <Typography sx={styles.outOfStock}>Out of stock</Typography>
            </Box>
            <OutOfStock
              isOpen={isOutOfStockModalOpen}
              handleClose={handleCloseOutOfStockModal}
              onUpdateQuantity={setQuantityAmount}
              onSetOutOfStock={handleMarkNotAvailable}
            />
          </>
        )}
      </Box>

      {isConfirmActions && isBuyer && !isWaiting && (
        <Box sx={styles.actionsWrap}>
          <IconButton disabled={isDisabled} disableRipple sx={styles.closeIcon} onClick={handleRemoveModalOpen}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {!isBuyer && !isRestricted && (
        <Box sx={styles.actionsWrap}>
          <SellerActionsMenu
            disabled={isDisabled}
            open={open}
            anchorEl={anchorEl}
            isRemoved={isRemoved}
            isUnavailable={isUnavailable}
            handleClick={handleClick}
            handleClose={handleClose}
            handleMarkNotAvailable={handleMarkNotAvailable}
            handleRemove={handleRemove}
          />
        </Box>
      )}

      {isModalOpen && (
        <RemoveProductPopup
          title="Remove product?"
          handleClose={handleCloseRemovePopup}
          isOpen={isModalOpen}
          handleRemove={handleRemoveRequest}
          isLoading={isLoading}
        />
      )}

      {isLastModalOpen && (
        <RemoveLastProductPopup
          handleClose={handleCloseRemoveLastPopup}
          isOpen={isLastModalOpen}
          handleRemove={handleCancel}
          isLoading={isCancelLoading}
        />
      )}
    </Box>
  );
};
