import posthog from 'posthog-js';

const API_KEY = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;

export const initPostHog = () => {
  if (API_KEY && !window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
    posthog.init(API_KEY, {
      api_host: 'https://hog.sproutzo.com',
      person_profiles: 'always',
      capture_pageview: false,
      disable_session_recording: true,
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    });
    return posthog;
  }
  return null;
};
