import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  notFoundContainer: {
    display: 'flex',
    gap: '24px',
    svg: {
      height: '100px',
      width: '100px',
      [`@media (max-width: ${breakpoints.tablet}px)`]: {
        height: '64px',
        width: '64px',
      },
    },
  },
  description: (isClose: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    ...(isClose && { justifyContent: 'center' }),
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      justifyContent: 'space-around',
    },
  }),
  title: {
    margin: '0px',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '0px',
      fontSize: '14px',
      lineHeight: '17px',
    },
  },
  text: {
    maxWidth: '272px',
    margin: '8px 0px',
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'flex-start',
    maxWidth: '680px',
    width: '100%',
    gap: '4px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      gap: '16px',
    },
  },
  bottomText: {
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,
    maxWidth: '680px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  button: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 -14px -8px 0',
    gap: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '0px',
    },
  },
  emailContainer: {
    flex: 1,
    marginBottom: '0px',
  },
  emailInput: {
    borderRadius: '16px',
    marginBottom: '0px',
    flex: 1,
  },

  confirmButton: {
    minWidth: '90px',
    color: customTheme.custom.white,
  },
  inputStyles: {
    padding: '14px 16px',
    borderRadius: '16px',
    width: '100%',
    backgroundColor: customTheme.custom.white,
    ':hover': {
      backgroundColor: customTheme.custom.white,
    },
    ':active': {
      backgroundColor: customTheme.custom.white,
    },
    '& .MuiInputBase-input': {
      overflowX: 'hidden',
      ...scrollMixin,
    },
  },
  cantFindBtn: {
    width: 'fit-content',
    marginBottom: '16px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '0px',
    },

    backgroundColor: '#c67edf',
    ':hover': {
      backgroundColor: '#c67edf !important',
    },
    ':active': {
      backgroundColor: '#c67edf !important',
    },
  },
};
