import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  contentWrap: {
    position: 'relative',
    flex: '1',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexGrow: 1,
      overflow: 'hidden',
    },
  },
  imageContentWrap: {
    display: 'flex',
    width: '56%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
  },
  imageWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flex: '0 0 38%',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  imageBg: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  content: (isBlur: boolean) => ({
    height: 'calc(100% - 32px)',
    padding: '16px',
    color: customTheme.custom.white,
    background: customTheme.palette.primary.P100,

    ...(isBlur && {
      background: 'rgba(69, 69, 69, 0.6)',
      backdropFilter: 'blur(25px)',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '8px 8px 8px 12px',
      height: '100%',
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
  }),
  name: {
    fontWeight: '600',
    lineHeight: '22px',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      boxSizing: 'border-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  place: {
    marginBottom: '20px',
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.blue.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '10px',
    },
  },
  descriptionTitle: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '18px',
  },
  description: {
    display: '-webkit-box',
    WebkitLineClamp: '6',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    lineHeight: '16px',
    color: customTheme.custom.black.B20,
  },
};
