import { FC } from 'react';

import { unstable_useBlocker as useBlocker, useBeforeUnload } from 'react-router-dom';
import { Box } from '@mui/material';

import { styles } from './styles';

interface PromptProps {
  when: boolean;
  allowToNavigate?: () => boolean;
}

const leaveText = 'Leave page? Changes that you made may not be saved.';

export const Prompt: FC<PromptProps> = ({ when, allowToNavigate }): JSX.Element => {
  useBlocker(() => {
    if (allowToNavigate?.()) {
      return false;
    }

    if (when) {
      // eslint-disable-next-line no-alert
      return !window.confirm(leaveText);
    }
    return false;
  });

  useBeforeUnload((e) => {
    if (allowToNavigate?.()) {
      return;
    }

    if (when) {
      // eslint-disable-next-line no-param-reassign
      e.returnValue = leaveText;
    }
  });

  return <Box aria-label="prompt" sx={{ ...styles.wrap }} />;
};
