import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  paper: {
    minWidth: '720px',
  },
  paperMobile: {
    minWidth: 'unset',
    padding: '14px',
  },
  subtitle: {
    marginBottom: '24px',
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '16px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0px 0px 8px',
    gap: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '-8px',
    },
  },
  confirmButton: {
    minWidth: '90px',
    color: customTheme.custom.white,
  },
  inputStyles: {
    padding: '14px 16px',
    borderRadius: '16px',

    '& .MuiInputBase-input': {
      overflowX: 'hidden',
      ...scrollMixin,
    },
  },
  emailContainer: {
    flex: 1,
    marginBottom: '0px',
  },
  emailInput: {
    borderRadius: '16px',
    marginBottom: '0px',
    flex: 1,
  },
};
