import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    padding: '64px 0px 24px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100vw',
      height: '164px',
      marginLeft: '-14px',
      marginRight: '-14px',
      padding: '32px 16px',
      borderRadius: 0,
      overflow: 'visible',
    },
  },
  title: {
    color: customTheme.custom.black.B1000,
    fontFamily: 'Poppins',
    fontSize: '74px',
    fontWeight: 700,
    lineHeight: 1.3,
    [`@media (max-width: ${breakpoints.laptop}px)`]: {
      fontSize: '58px',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '4px',
      fontSize: '24px',
      lineHeight: '28px',
      maxWidth: '100%',
    },
  },
  sub: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    color: customTheme.custom.black.B70,
    fontWeight: '600',
    marginBottom: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
      lineHeight: '18px',
      maxWidth: '100%',
    },
  },

  description: {
    maxWidth: '460px',
    textAlign: 'center',
    lineHeight: 2,
    fontSize: '14px',
  },
  pill: (color: string) => ({
    border: `1px solid ${color}`,
    color,
    padding: '0px 8px',
    borderRadius: '16px',
  }),
};
