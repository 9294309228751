import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { TeamMember } from 'app/api/company/types';
import { AvatarCard } from '../AvatarCard';
import { styles } from './styles';

interface TeamListProps {
  data: TeamMember[];
}

export const TeamList: FC<TeamListProps> = ({ data }) => {
  return (
    <>
      <Typography component="h3" sx={{ ...styles.title }}>
        Our Team
      </Typography>
      <Box sx={{ ...styles.listWrap }}>
        {data.map(({ id, ...employee }) => (
          <AvatarCard key={id} {...employee} />
        ))}
      </Box>
    </>
  );
};
