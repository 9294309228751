import { ProductDetail, ProductEdit } from 'app/api/products/types';

export enum Validator {
  STRING = 'STRING',
  STRING_NUMBER = 'STRING_NUMBER',
  NUMBER_MIN_MAX_UNITS = 'NUMBER_MIN_MAX_UNITS',
  NUMBER_RANGE = 'NUMBER_RANGE',
}

export const WeightUnits = {
  GRAM: 'grams',
  KILOGRAM: 'kg',
  POUND: 'lb',
};

export const QuantityUnits = {
  THOUSAND_SEEDS: 'thousand seeds',
  KILOGRAM: 'kg',
  POUND: 'lb',
};

export const SizeUnits = {
  CM: 'cm',
  INCH: 'inch',
};

export const LargeSizeUnits = {
  M: 'meters',
  CM: 'cm',
  INCH: 'inch',
};

export const TemperatureUnits = {
  celcius: '°C',
  farenheit: '°F',
};

export const TimeUnits = {
  DAYS: 'Days',
};

export const YieldUnits = {
  KG_HA: 'kg/ha',
};

export const PercentageUnits = {
  PERCENTAGE: '%',
};

export type FieldUnits =
  | typeof WeightUnits
  | typeof SizeUnits
  | typeof LargeSizeUnits
  | typeof TimeUnits
  | typeof PercentageUnits
  | typeof YieldUnits
  | typeof TemperatureUnits;

export type OtherFieldMapping = (
  | {
      validator: Validator.NUMBER_MIN_MAX_UNITS;
      units: FieldUnits;
    }
  | {
      validator: Validator.STRING | Validator.STRING_NUMBER | Validator.NUMBER_MIN_MAX_UNITS | Validator.NUMBER_RANGE;
    }
  | {
      validator: Validator.STRING;
      choices: string[];
    }
  | {
      validator: Validator.STRING;
      rows?: number;
    }
) & {
  label: string;
  key: keyof ProductEdit;
  placeholder?: string;
  description?: string;
  notes?: string;
  other_key: string;
  restrictedCrops?: Set<string>;
};

export const otherFieldDefinitions: OtherFieldMapping[] = [
  {
    label: 'Color',
    key: 'color',
    description: 'Outer color of the fruit',
    placeholder: 'ie. Red, Green, Yellow, etc.',
    other_key: 'color',
    validator: Validator.STRING,
  },
  {
    label: 'Other Color Details',
    key: 'color_other',
    other_key: 'color other',
    description: 'Multicolor related attributes',
    placeholder: 'ie. Yellow stripes, Dark Leaves, etc',
    validator: Validator.STRING,
  },
  {
    label: 'Inner/Flesh Color',
    key: 'inner_flesh_color',
    other_key: 'inner/ flesh color',
    description: 'Flesh, Pulp, Interior',
    placeholder: 'ie. Red, Green, Yellow, etc.',
    validator: Validator.STRING,
  },
  {
    label: 'Farming Method',
    key: 'farming_method',
    other_key: 'farming method',
    validator: Validator.STRING,
    choices: ['Organic', ''],
  },
  {
    label: 'Sci',
    key: 'sci',
    other_key: 'sci',
    validator: Validator.STRING_NUMBER,
    restrictedCrops: new Set(['Cotton']),
  },
  {
    label: 'g/tex',
    key: 'g_tex',
    other_key: 'g/tex',
    validator: Validator.STRING_NUMBER,
    restrictedCrops: new Set(['Cotton']),
  },
  {
    label: 'Shape',
    key: 'shape',
    other_key: 'shape',
    placeholder: 'ie. Flat, Round, Oval, etc.',
    validator: Validator.STRING,
  },
  {
    label: 'Plant Shape',
    key: 'plant_shape',
    other_key: 'plant shape',
    placeholder: 'ie. Erect, Open, Compact, etc.',
    validator: Validator.STRING,
  },
  {
    label: 'Boll Shape',
    key: 'boll_shape',
    other_key: 'boll shape',
    validator: Validator.STRING,
    restrictedCrops: new Set(['Cotton']),
  },
  {
    label: 'Warts',
    key: 'warts',
    other_key: 'warts',
    placeholder: 'ie. Pebbly, Rigid, etc.',
    validator: Validator.STRING,
  },
  { label: 'Width', key: 'width', other_key: 'width', validator: Validator.NUMBER_MIN_MAX_UNITS, units: SizeUnits },
  { label: 'Length', key: 'length', other_key: 'length', validator: Validator.NUMBER_MIN_MAX_UNITS, units: SizeUnits },
  {
    label: 'Plant height',
    key: 'plant_height',
    other_key: 'plant height',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
    units: SizeUnits,
  },
  { label: 'Ridges', key: 'ridges', other_key: 'ridges', placeholder: 'ie. 4, 5, etc.', validator: Validator.STRING },
  {
    label: 'Weight',
    key: 'weight',
    other_key: 'weight',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
    units: WeightUnits,
  },
  {
    label: 'Surface',
    key: 'surface',
    other_key: 'surface',
    placeholder: 'ie. Smooth, Wrinkled, Spiny, etc.',
    validator: Validator.STRING,
  },
  {
    label: 'Maturity',
    key: 'maturity',
    other_key: 'maturity',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
    units: TimeUnits,
  },
  { label: 'Prickles', key: 'prickles', other_key: 'prickles', validator: Validator.STRING },
  {
    label: 'Firmness',
    key: 'firmness',
    other_key: 'firmness',
    validator: Validator.STRING,
    choices: ['', 'Moderately Firm', 'Firm', 'Very Firm'],
  },
  {
    label: 'Pungency',
    key: 'pungency',
    other_key: 'pungency',
    validator: Validator.STRING,
    choices: ['', 'Low Pungency', 'Mild Pungency', 'Pungent', 'Very Pungency'],
  },
  {
    label: 'Flowering Habit',
    key: 'flowering_habit',
    other_key: 'flowering habit',
    validator: Validator.STRING,
    choices: ['', 'Gynoecious', 'Monoecious'],
  },
  {
    label: 'Boll Size',
    key: 'boll_size',
    other_key: 'boll size',
    validator: Validator.STRING,
    restrictedCrops: new Set(['Cotton']),
  },
  { label: 'Flowering', key: 'flowering', other_key: 'flowering', validator: Validator.STRING },
  {
    label: 'Head Type',
    key: 'head_type',
    other_key: 'head type',
    validator: Validator.STRING,
    choices: ['', 'Loose', 'Compact', 'Semi-Compact', 'Very Compact'],
    restrictedCrops: new Set([
      'Orach',
      'Perilla',
      'Tatsoi',
      'Swiss chard',
      'Mustard Greens',
      'Garden rocket',
      'Sorrel',
      'New Zealand spinach',
      'Mâche',
      'Leaf celery',
      'Chard',
      'Pai tsai',
      'Chinese Mustard',
      'Chinese kale',
      'Kale',
      'Lettuce',
      'Spinach',
      'Bok choy',
      'Chinese cabbage',
      'Pak choi',
      'Watercress',
      'Cress',
    ]),
  },
  {
    label: 'Monopodia',
    key: 'monopodia',
    other_key: 'monopodia',
    validator: Validator.STRING,
    restrictedCrops: new Set(['Cotton']),
  },
  {
    label: 'Thickness',
    key: 'thickness',
    other_key: 'thickness',
    validator: Validator.STRING,
    restrictedCrops: new Set(['Pea', 'Bean']),
  },
  { label: 'Grain Size', key: 'grain_size', other_key: 'grain size', validator: Validator.STRING },
  {
    label: 'Micronaire',
    key: 'micronaire',
    other_key: 'micronaire',
    validator: Validator.STRING_NUMBER,
    restrictedCrops: new Set(['Cotton']),
  },
  { label: 'Uniformity', key: 'uniformity', other_key: 'uniformity', validator: Validator.STRING },
  {
    label: 'Determinate',
    key: 'determinate',
    other_key: 'determinate',
    validator: Validator.STRING,
    choices: ['', 'Determinate', 'Indeterminate', 'Semi-determinate', 'Semi-indeterminate'],
  },
  {
    label: 'Oil Content',
    key: 'oil_content',
    other_key: 'oil content',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
    units: PercentageUnits,
  },
  { label: 'Root length', key: 'root_length', other_key: 'root length', validator: Validator.STRING, notes: 'unknown' },
  { label: 'Keeping Days', key: 'keeping_days', other_key: 'keeping days', validator: Validator.NUMBER_RANGE },
  { label: 'Rows per Ear', key: 'rows_per_ear', other_key: 'rows per ear', validator: Validator.NUMBER_RANGE },
  {
    label: 'Seeds per Pod',
    key: 'seeds_per_pod',
    other_key: 'seeds per pod',
    validator: Validator.NUMBER_RANGE,
    restrictedCrops: new Set(['Pea', 'Bean']),
  },
  {
    label: 'Sugar Content',
    key: 'sugar_content',
    other_key: 'sugar content',
    validator: Validator.STRING,
    choices: ['', 'Low', 'Medium', 'High'],
  },
  {
    label: 'Flowering Time',
    key: 'flowering_time',
    other_key: 'flowering time',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
    units: TimeUnits,
  },
  { label: 'Grains per Row', key: 'grains_per_row', other_key: 'grains per row', validator: Validator.NUMBER_RANGE },
  {
    label: 'Number of Branches',
    key: 'no_of_branches',
    other_key: 'no of branches',
    validator: Validator.NUMBER_RANGE,
    notes: 'unknown',
  },
  {
    label: 'Pods per Plant',
    key: 'pods_per_plant',
    other_key: 'pods per plant',
    validator: Validator.NUMBER_RANGE,
    restrictedCrops: new Set(['Pea', 'Bean']),
  },
  {
    label: 'Works Well For',
    key: 'works_well_for',
    other_key: 'works well for',
    placeholder: 'ie. Flower-beds, Rock Gardens, House Plants, Lawn, etc.',
    validator: Validator.STRING,
  },
  {
    label: 'Amylose Content',
    key: 'amylose_content',
    other_key: 'amylose content',
    validator: Validator.NUMBER_RANGE,
    restrictedCrops: new Set(['Rice']),
  },
  {
    label: 'Number of Lobes',
    key: 'number_of_lobes',
    other_key: 'number of lobes',
    validator: Validator.NUMBER_RANGE,
    restrictedCrops: new Set(['Sweet Pepper', 'Hot Pepper']),
  },
  {
    label: 'Potential Yield',
    key: 'potential_yield',
    other_key: 'potential yield',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
    units: YieldUnits,
  },
  {
    label: 'Sulphur Content',
    key: 'sulphur_content',
    other_key: 'sulphur content',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
    units: PercentageUnits,
  },
  { label: 'Adaptive Climate', key: 'adaptive_climate', other_key: 'adaptive climate', validator: Validator.STRING },
  {
    label: 'First Ear Height',
    key: 'first_ear_height',
    other_key: 'first ear height',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
    units: LargeSizeUnits,
    restrictedCrops: new Set(['Pea', 'Bean']),
  },
  {
    label: 'Milling Recovery',
    key: 'milling_recovery',
    other_key: 'milling recovery',
    validator: Validator.STRING_NUMBER,
    restrictedCrops: new Set(['Rice']),
  },
  {
    label: 'Crude Fat Content',
    key: 'crude_fat_content',
    other_key: 'crude fat content',
    validator: Validator.STRING,
    notes: 'unknown',
  },
  { label: 'Planting Interval', key: 'planting_interval', other_key: 'planting interval', validator: Validator.STRING },
  { label: 'Tillering Ability', key: 'tillering_ability', other_key: 'tillering ability', validator: Validator.STRING },
  {
    label: 'Tillers per Plant',
    key: 'tillers_per_plant',
    other_key: 'tillers per plant',
    validator: Validator.NUMBER_RANGE,
    notes: 'need to fix',
  },
  {
    label: 'Brix/Sugar Content',
    key: 'brix_sugar_content',
    other_key: 'brix/sugar content',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
    units: PercentageUnits,
  },
  {
    label: 'Fruits per Cluster',
    key: 'fruits_per_cluster',
    other_key: 'fruits per cluster',
    validator: Validator.NUMBER_RANGE,
  },
  {
    label: 'Optimal Field Type',
    key: 'optimal_field_type',
    other_key: 'optimal field type',
    placeholder: 'ie. Open field, Green house, etc.',
    validator: Validator.STRING,
  },
  {
    label: 'Seeds Rate per Acre',
    key: 'seed_rate_per_acre',
    other_key: 'seed rate per acre',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
  },
  {
    label: 'Shelling Percentage',
    key: 'shelling_percentage',
    other_key: 'shelling percentage',
    validator: Validator.STRING_NUMBER,
    restrictedCrops: new Set(['Pea', 'Bean']),
  },
  {
    label: 'Potential Yield t/ha',
    key: 'potential_yield_t_ha',
    other_key: 'potential yield t/ha',
    validator: Validator.STRING,
    notes: 'potentially invalid',
  },
  {
    label: 'Seed Yield per Plant',
    key: 'seed_yield_per_plant',
    other_key: 'seed yield per plant',
    validator: Validator.NUMBER_RANGE,
    notes: 'unkown',
  },
  {
    label: 'Average Grain per Ear',
    key: 'average_grain_per_ear',
    other_key: 'average grain per ear',
    validator: Validator.STRING,
    restrictedCrops: new Set(['Pea', 'Bean']),
  },
  {
    label: 'Degree of Development',
    key: 'degree_of_development',
    other_key: 'degree of development',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
    units: SizeUnits,
  },
  {
    label: 'Fruit Pods per Plant',
    key: 'fruits_pods_per_plant',
    other_key: 'fruits/pods per plant',
    validator: Validator.NUMBER_RANGE,
  },
  {
    label: 'Relative Plant Height',
    key: 'relative_plant_height',
    other_key: 'relative plant height',
    validator: Validator.STRING,
    choices: ['', 'Short', 'Medium-Short', 'Medium', 'Medum-Tall', 'Tall'],
  },
  {
    label: 'Seeds per gram',
    key: 'approx_seeds_per_gram',
    other_key: 'approx. seeds per gram',
    validator: Validator.STRING_NUMBER,
  },
  {
    label: 'Fruit Skin Wall Thickness',
    key: 'fruit_skin_wall_thickness',
    other_key: 'fruit skin/wall thickness',
    validator: Validator.STRING,
    notes: 'unknown',
  },
  {
    label: 'Ideal Growing Temperature',
    key: 'ideal_growing_temperature',
    other_key: 'ideal growing temperature',
    validator: Validator.NUMBER_MIN_MAX_UNITS,
    units: TemperatureUnits,
  },
  {
    label: 'Grain Length to Width Ratio',
    key: 'grain_length_to_width_ratio',
    other_key: 'grain length to width ratio',
    validator: Validator.STRING_NUMBER,
    restrictedCrops: new Set(['Rice']),
  },
  {
    label: 'Disease & Pest Tolerance Specialties',
    key: 'disease_pest_tolerance_specialties',
    other_key: 'disease/pest tolerance specialties',
    placeholder: 'ie. ZYMV (IR), TSWV, TYLCV, Fusarium, etc.',
    validator: Validator.STRING,
    rows: 2,
  },
  {
    label: 'Environmental Tolerance Specialties',
    key: 'environmental_tolerance_specialties',
    other_key: 'environmental tolerance specialties',
    placeholder: 'ie. Bolting, Drought, Cracking, Cold, etc.',
    validator: Validator.STRING,
    rows: 2,
  },
  { label: 'brix', key: 'brix', other_key: 'brix', validator: Validator.NUMBER_MIN_MAX_UNITS, units: PercentageUnits },
  { label: 'relative_height', key: 'relative_height', other_key: 'relative height', validator: Validator.STRING },
  {
    label: 'Fruits per Plant',
    key: 'fruits_per_plant',
    other_key: 'fruits per plant',
    validator: Validator.NUMBER_RANGE,
  },
  {
    label: 'Number of Ridges',
    key: 'number_of_ridges',
    other_key: 'number of ridges',
    validator: Validator.NUMBER_RANGE,
    restrictedCrops: new Set(['Okra']),
  },
  {
    label: 'Pods per Cluster',
    key: 'pods_per_cluster',
    other_key: 'pods per cluster',
    validator: Validator.NUMBER_RANGE,
    restrictedCrops: new Set(['Pea', 'Bean']),
  },
  { label: 'Relative Maturity', key: 'relative_maturity', other_key: 'relative maturity', validator: Validator.STRING },
  {
    label: 'Number of Cuttings',
    key: 'number_of_cuttings',
    other_key: 'number of cuttings',
    validator: Validator.STRING,
  },
  {
    label: 'Kernel Rows per Ear',
    key: 'kernel_rows_per_ear',
    other_key: 'kernel rows per ear',
    validator: Validator.NUMBER_RANGE,
    restrictedCrops: new Set(['Pea', 'Bean']),
  },
  {
    label: 'Number of Pods per Plant',
    key: 'no_of_pod_per_plant',
    other_key: 'no of pod per plant',
    validator: Validator.STRING_NUMBER,
    restrictedCrops: new Set(['Pea', 'Bean']),
  },
  {
    label: 'Number of Monopodia',
    key: 'number_of_monopodia',
    other_key: 'number of monopodia',
    validator: Validator.NUMBER_RANGE,
    restrictedCrops: new Set(['Cotton']),
  },
  {
    label: 'Kernel Grains per Row',
    key: 'kernels_grains_per_row',
    other_key: 'kernels/grains per row',
    validator: Validator.NUMBER_RANGE,
    restrictedCrops: new Set(['Pea', 'Bean']),
  },
  { label: 'Planting Distance', key: 'planting_distance', other_key: 'planting distance', validator: Validator.STRING },
  { label: 'Seeds per gm', key: 'seeds_per_gm', other_key: 'seeds per gm', validator: Validator.STRING_NUMBER },
  {
    label: 'Days to Flower',
    key: 'days_to_flower',
    other_key: 'days to flower',
    placeholder: 'ie. 90, 100, 120, etc. ',
    validator: Validator.STRING_NUMBER,
  },
  {
    label: 'Days to Sprout',
    key: 'days_to_sprout',
    other_key: 'days to sprout',
    placeholder: 'ie. 4-10, 7-10, 12-15, etc.',
    validator: Validator.NUMBER_RANGE,
  },
  { label: 'Sun Requirements', key: 'sun_requirements', other_key: 'sun requirements', validator: Validator.STRING },
];

export const otherFieldMapping = otherFieldDefinitions.reduce((acc, field) => {
  // eslint-disable-next-line
  acc[field.key] = field;
  return acc;
}, {} as { [key: string]: OtherFieldMapping });

export const convertProductDetailsToFlatForm = (productDetail: ProductDetail) => {
  const flatProduct: ProductEdit = {
    name: productDetail.name ?? '',
    variety: productDetail.variety ?? '',
    crop: productDetail.crop,
    description: productDetail.description ?? '',
    countriesRestrictedAt: productDetail.countriesRestrictedAt ?? [],
  };

  // eslint-disable-next-line
  for (const field of otherFieldDefinitions) {
    if (productDetail && field.other_key in (productDetail?.other ?? {})) {
      // NOTE: This is very important to keep the type above in sync with the type of ProductEdit
      // eslint-disable-next-line
      // @ts-ignore
      flatProduct[field.key] = productDetail?.other?.[field.other_key] ?? undefined;
    }
  }
  return flatProduct;
};
