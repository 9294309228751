import { createTheme, keyframes } from '@mui/material';
import { breakpoints } from './breakpoints';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

export const customTheme = {
  palette: {
    primary: {
      main: '#278B9F',
      P100: '#278B9F',
      P70: '#57B0C2',
      P50: '#93C5CF',
      P30: '#C7E2E3',
      P20: '#D9EBEC',
      P10: '#ECF5F6',
      P5: '#F6FAFA',
    },
    secondary: {
      main: '#2FB281',
      S100: '#2FB281',
      S80: '#46C494',
      S70: '#6DC9A7',
      S50: '#A7DEC9',
      S30: '#CBEBDF',
      S20: '#DCF2E9',
      S10: '#EDF8F4',
      S5: '#F6FCFA',
    },
    info: {
      main: '#5E6062',
    },
  },
  typography: {
    allVariants: {
      fontFamily: `'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif`,
    },
  },
  custom: {
    black: {
      B1000: '#000000',
      B100: '#252728',
      B70: '#5E6062',
      B50: '#8C8D8E',
      B30: '#BABBBC',
      B20: '#D1D2D2',
      B10: '#E8E8E8',
      B5: '#F3F3F4',
    },
    blue: {
      B20: '#E2EEF3',
      B50: '#E0F7FB',
      B100: '#C2EEF7',
    },
    gray: {
      G10: '#FAFAFA',
      G50: '#F5F5F5',
      G100: '#C6C6C6',
      G200: '#D9D9D9',
      G400: '#A1A1AA',
      G500: '#71717A',
      G600: '#52525B',
      G700: '#545454',
    },
    red: {
      light: '#D47C7C',
      dark: '#851A1A',
    },
    outOfStock: '#A93D3D',
    blackWithOpacity: 'rgba(37, 39, 40, 0.5)',
    success: '#2FB380',
    error: '#D13D3E',
    errorLight: '#FEFBFB',
    warning: '#FCA033',
    info: '#317FD5',
    white: '#FFFFFF',
    veryLightGrey: '#FEFEFE',
    darkGrey: '#8B8B8B',
    veryDarkGrey: '#3E3E3E',
    mintTulip: '#B7F1F1',
    hippieBlue: '#66A0AE',
    atomicTangerine: '#FCA66B',
    sweetCorn: '#F8DF89',
    mainBg: '#FBFBFB',
    neutralLight: '#42526E',
    awaiting: '#C59A0B',
    notificationBg: '#FBFEFD',
    reject: '#DF7959',
    hover: '#F7F8F8',
    organic: '#58BD93',
    bannerBlue: '#004272',
    brightGreen: '#10B981',
  },
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
  shadow: {
    boxShadow: {
      small: '0px 2px 4px rgba(0, 0, 0, 0.12)',
      smallHover: '2px 4px 8px rgba(0, 0, 0, 0.12)',
      standard: '0px 4px 15px rgba(0, 0, 0, 0.1)',
      hover: '4px 8px 15px rgba(94, 96, 98, 0.2)',
    },
  },
  transition: {
    default: 'all 0.08s ease-in-out',
    duration: {
      short: '0.08s',
    },
  },
  animations: {
    fadeIn: keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }`,
    height: keyframes`
    0% { max-height: 0; }
    100% { max-height: 100vh; }`,
  },
};

const theme = createTheme(customTheme);

export default theme;
