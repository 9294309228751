import { CSSProperties, FC, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { AppBar, Box, IconButton, SxProps, Toolbar } from '@mui/material';

import { ReactComponent as Logo } from 'assets/images/headerLogo.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menuBurger.svg';
import { getDefaultUserRoute } from 'app/providers/utils';
import { CommonButton } from 'components';
import { useAuth } from 'hooks';
import { useAppDispatch } from 'state/hooks';
import { setSignupModal } from 'state/slices/account';

import { HeaderSearch } from '../../../HeaderSearch';
import { Notifications } from '../../../Notifications';
import { UserMenu } from '../../../UserMenu';
import { styles } from './styles';

interface MobileHeaderProps {
  isShadow: boolean;
  isActionsEnabled?: boolean;
  logoSx?: SxProps;
  minHeight?: CSSProperties['minHeight'];
  onMenuButtonClick?: () => void;
}

const RequestAccessLogin = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setSignupModal({ isOpen: true, state: 'welcome', source: 'header' }));
  };
  return (
    <CommonButton variant="contained" sx={styles.requestAccessBtn} onClick={handleClick}>
      Free Access
    </CommonButton>
  );
};

export const MobileHeader: FC<MobileHeaderProps> = ({
  isShadow,
  isActionsEnabled = true,
  logoSx,
  minHeight,
  onMenuButtonClick,
}) => {
  const navigate = useNavigate();
  const { isBuyer, activeUser } = useAuth();

  const handleRedirectHome = useCallback(() => {
    navigate(getDefaultUserRoute(isBuyer));
  }, [isBuyer, navigate]);
  const isBuyerOrNotActive = isBuyer || !activeUser;
  return (
    <AppBar position="fixed" sx={styles.mainWrap(isShadow, isBuyerOrNotActive, minHeight)}>
      <Toolbar sx={styles.toolBar(!isActionsEnabled)}>
        {isActionsEnabled && activeUser && (
          <Box sx={styles.leftSideWrap}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={onMenuButtonClick}
              sx={styles.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Notifications />
          </Box>
        )}

        <Box sx={{ ...styles.logo, ...logoSx }}>
          <Logo onClick={handleRedirectHome} />
        </Box>

        {isActionsEnabled && activeUser && <UserMenu />}
        {!activeUser && <RequestAccessLogin />}
      </Toolbar>

      {isBuyerOrNotActive && isActionsEnabled && <HeaderSearch />}
    </AppBar>
  );
};
