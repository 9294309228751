import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ParsedLinkText } from './ParsedLinkText';
import { styles } from './styles';

interface SBTextMessageBodyProps {
  isCurrentUser: boolean;
  isHighlighted: boolean;
  isCurrentCompany?: boolean;
  text: string;
}

export const SBUserTextMessageBody: FC<SBTextMessageBodyProps> = ({
  isCurrentUser,
  isHighlighted,
  isCurrentCompany = false,
  text,
}) => {
  const messageLines = text.split(/\r?\n/);

  return (
    <Box sx={styles.wrap({ isCurrentUser, isCurrentCompany, isHighlighted })}>
      {messageLines.map((line) => (
        <Typography component="span" sx={styles.textLine} key={line}>
          <ParsedLinkText text={line} />
        </Typography>
      ))}
    </Box>
  );
};
