export const ROUTES = {
  _: '/',
  auth: {
    login: '/login',
    accountActivation: '/account-activation/',
    resetPassword: '/reset-password',
    createNewPassword: '/create-new-password/',
  },
  buyer: {
    _: '/buyer/home',
    dashboard: {
      _: '/buyer/dashboard',
      tab: '/buyer/dashboard/:tab',
    },
    products: '/buyer/products/:type',
    productsExtended: '/buyer/products/:type/:subType',
    quoteRequestCompany: '/buyer/quote/request/:companyDisplayId',
    sampleRequestCompany: '/buyer/sample/request/:companyDisplayId',
    quoteRequest: '/buyer/quote/request/:companyDisplayId/:productId',
    sampleRequest: '/buyer/sample/request/:companyDisplayId/:productId',
    quoteRequestAdd: '/buyer/quote/add-request/:companyDisplayId/:quoteId',
    sampleRequestAdd: '/buyer/quote/add-sample/:companyDisplayId/:quoteId',
    placeOrder: '/buyer/place-order/:quoteId',
    onboarding: '/buyer/onboarding',
  },
  seller: {
    dashboard: {
      _: '/seller/dashboard',
      tab: '/seller/dashboard/:tab',
    },
    edit: {
      _: '/seller/:companyDisplayId/edit',
    },
  },
  common: {
    privacyPolicy: '/privacy-policy',
    termsOfService: '/terms-of-service',
    about: '/about',
    contact: '/contact',
    company: '/company/:displayId',
    messages: '/messages',
    chat: '/messages/:id',
    product: '/product/:id',
    orderDetails: '/order-details',
    order: '/order/:id',
    notifications: '/notifications',
    quoteRequestDetails: '/quote-request/:id',
    sampleRequestDetails: '/sample-request/:id',
    changePassword: '/change-password',
    maintenance: '/maintenance',
    shipmentTracking: '/shipment-tracking/:id',
    faq: '/faq',
  },
};
