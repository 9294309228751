import { useCallback, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import * as tracker from 'analytics/tracker';

import { useProductsListQuery } from 'hooks';
import { AnalyticsName, getSearchPerformedEventResults } from 'analytics';
import {
  FEATURED_SELLERS_TYPE,
  FEATURED_TYPE,
  colorFilterAll,
  continentFilterAll,
  geneticFilterValues,
  organicFilterValues,
  resistancesFilterAll,
} from '../../../constants';
import { SortTypes } from '../../../types';

const PRODUCTS_COUNT_STEP = 40;

interface UseProductsProps {
  searchParam: string | undefined;
  geneticTypeValue: string;
  colorValue: (string | boolean)[];
  continentValue: (string | boolean)[];
  resistancesValue: (string | boolean)[];
  applyResistancesFilter: boolean;
  sortValue: string;
  minWeightValue: number | undefined;
  maxWeightValue: number | undefined;
  applyWeightFilter: boolean;
  minMaturityValue: number | undefined;
  maxMaturityValue: number | undefined;
  applyMaturityFilter: boolean;
  organicValue: string;
}

export const useProducts = ({
  searchParam,
  geneticTypeValue,
  colorValue,
  continentValue,
  resistancesValue,
  applyResistancesFilter,
  sortValue,
  minWeightValue,
  maxWeightValue,
  applyWeightFilter,
  minMaturityValue,
  maxMaturityValue,
  applyMaturityFilter,
  organicValue,
}: UseProductsProps) => {
  const { type, subType } = useParams();

  const geneticType = useMemo(() => {
    if (geneticTypeValue === geneticFilterValues[0].value) {
      return undefined;
    }

    return geneticTypeValue;
  }, [geneticTypeValue]);

  const organicSeed = useMemo(() => {
    if (organicValue === organicFilterValues[0].value) {
      return undefined;
    }

    return organicValue;
  }, [organicValue]);

  const color = useMemo(() => {
    if (colorValue?.[0] === colorFilterAll.value) {
      return undefined;
    }

    return colorValue?.filter((val) => val).map((val) => String(val));
  }, [colorValue]);

  const continent = useMemo(() => {
    if (continentValue?.[0] === continentFilterAll.value) {
      return undefined;
    }

    return continentValue?.filter((val) => val).map((val) => String(val));
  }, [continentValue]);

  const resistances = useMemo(() => {
    if (resistancesValue?.[0] === resistancesFilterAll.value) {
      return undefined;
    }

    return resistancesValue?.filter((val) => val).map((val) => String(val));
  }, [resistancesValue]);

  const sort = useMemo(() => {
    if (sortValue === SortTypes.RECENT) {
      return undefined;
    }

    return sortValue;
  }, [sortValue]);

  const cropType = useMemo(() => {
    if (searchParam) {
      return undefined;
    }

    if (type === FEATURED_TYPE) {
      return { featured: true };
    }

    if (type === FEATURED_SELLERS_TYPE) {
      return { featuredSeller: true };
    }

    return subType ? { crop: subType } : { cropFamily: type };
  }, [type, subType, searchParam]);

  const {
    data: productsListData,
    isLoading: isProductsLoading,
    isFetchingNextPage: isProductsNextPageLoading,
    hasNextPage: hasProductsNextPage,
    fetchNextPage: fetchProductsNextPage,
  } = useProductsListQuery({
    cacheTime: 0,
    limit: PRODUCTS_COUNT_STEP,
    offset: 1,
    search: searchParam,
    geneticType,
    sort,
    ...(color?.length && { color }),
    ...(continent?.length && { continent }),
    ...(resistances?.length && { resistances }),
    ...cropType,
    ...(!!minWeightValue && { minWeight: minWeightValue }),
    ...(!!maxWeightValue && { maxWeight: maxWeightValue }),
    ...(!!minMaturityValue && { maturityMin: minMaturityValue }),
    ...(!!maxMaturityValue && { maturityMax: maxMaturityValue }),
    ...((!!maxWeightValue || !!minWeightValue) && { applyWeightFilter }),
    ...((!!maxMaturityValue || !!minMaturityValue) && { applyMaturityFilter }),
    ...((!!resistances?.length || applyResistancesFilter) && { applyResistancesFilter }),
    ...(!!organicSeed && { organic: true }),

    onSuccessAction: (data) => {
      const results = getSearchPerformedEventResults(data) ?? [];

      const analyticsOptions = {
        query: searchParam ?? '',
        filter_color: color ?? [],
        filter_continent: continent ?? [],
        // SPZ-1550: add resistances
        // filter_resistances: resistances ?? [],
        // include_products_with_incomplete_resistances: !!resistances?.length && !applyResistancesFilter,
        filter_genetic_type: geneticType ? [geneticType] : [],
        filter_organic: organicSeed ? ['Organic Only'] : [],
        min_weight: minWeightValue,
        max_weight: maxWeightValue,
        include_products_with_incomplete_weight: !!maxWeightValue || !!minWeightValue ? !applyWeightFilter : undefined,
        min_maturity: minMaturityValue,
        max_maturity: maxMaturityValue,
        include_products_with_incomplete_maturity:
          !!maxMaturityValue || !!minMaturityValue ? !applyMaturityFilter : undefined,
        results,
        results_length: data?.pages?.[0].count ?? 0,
      };

      tracker.track(AnalyticsName.SEARCH_PERFORMED, analyticsOptions);
    },
  });

  const onLoadMore = useCallback(() => {
    if (!isProductsLoading && hasProductsNextPage) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchProductsNextPage();
    }
  }, [fetchProductsNextPage, hasProductsNextPage, isProductsLoading]);

  const products = useMemo(
    () => productsListData?.pages?.flatMap((item) => item?.results ?? []) ?? [],
    [productsListData?.pages],
  );

  return {
    onLoadMore,
    products,
    isLoading: isProductsLoading,
    isProductsNextPageLoading,
    hasNextPage: hasProductsNextPage,
  };
};
