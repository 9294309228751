import { useEffect, useMemo, useState } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as tracker from 'analytics/tracker';

import {
  getProductsColors,
  getProductsContinents,
  FilterParams,
  getProductsSeedsWeight,
  getProductsSeedsMaturity,
  getProductsSeedsResistances,
  getProductsOrganicFilter,
} from 'app/api/products';
import { AnalyticsName } from 'analytics';
import {
  FEATURED_SELLERS_TYPE,
  FEATURED_TYPE,
  FiltersNames,
  colorFilterAll,
  continentFilterAll,
  resistancesFilterAll,
  geneticFilterValues,
  organicFilterValues,
  sortValues,
  SLIDER_STEPS_COUNT,
  WEIGHT_SLIDER_STEP_ROUND_VALUE,
  MATURITY_SLIDER_STEP_ROUND_VALUE,
} from '../constants';
import { parseSearchParams } from '../utils';

// SPZ-1550: remove after API integration
import { resistancesMockData } from '../mockData';

export const useFilters = (searchParam: string | undefined) => {
  const { type, subType } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [parsedFilters, setParsedFilters] = useState<FilterParams>();

  const cropType = useMemo(() => {
    setParsedFilters(undefined);

    if (searchParam) {
      return undefined;
    }

    if (type === FEATURED_TYPE) {
      return { featured: true };
    }

    if (type === FEATURED_SELLERS_TYPE) {
      return { featuredSeller: true };
    }

    return subType ? { crop: subType } : { cropFamily: type };
  }, [type, subType, searchParam]);

  const { data: colorsData, isLoading: colorsLoading } = useQuery(
    ['products-colors', searchParam, cropType],
    () => getProductsColors({ search: searchParam, ...cropType }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const { data: continentsData, isLoading: continentsLoading } = useQuery(
    ['products-continents', searchParam, cropType],
    () => getProductsContinents({ search: searchParam, ...cropType }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const {
    // SPZ-1550: remane to resistancesData
    data: resistancesApiData,
    isLoading: resistancesLoading,
  } = useQuery(
    ['products-resistances', searchParam, cropType],
    () => getProductsSeedsResistances({ search: searchParam, ...cropType }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      // SPZ-1550: enable query
      enabled: false,
    },
  );

  // SPZ-1550:  remove
  const resistancesData = useMemo(() => {
    return resistancesApiData ?? resistancesMockData;
  }, [resistancesApiData]);

  const { data: weightLimit, isLoading: weightLoading } = useQuery(
    ['products-weight', searchParam, cropType],
    () => getProductsSeedsWeight({ search: searchParam, ...cropType }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const { data: maturityLimit, isLoading: maturityLoading } = useQuery(
    ['products-maturity', searchParam, cropType],
    () => getProductsSeedsMaturity({ search: searchParam, ...cropType }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
  const { data: organicFilter, isLoading: organicLoading } = useQuery(
    ['organic', searchParam, cropType],
    () => getProductsOrganicFilter({ search: searchParam, ...cropType }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const isOrganicFilter = !!organicFilter?.organic;

  const minWeightLimit = weightLimit?.minWeight ?? 0;
  const maxWeightLimit = weightLimit?.maxWeight ?? 0;

  const weightStep = useMemo(() => {
    const range = maxWeightLimit - minWeightLimit;
    const step = range / SLIDER_STEPS_COUNT;
    const roundedStep =
      range < 200
        ? Math.round(step)
        : Math.round(step / WEIGHT_SLIDER_STEP_ROUND_VALUE) * WEIGHT_SLIDER_STEP_ROUND_VALUE;

    return range < 10 ? 1 : roundedStep;
  }, [maxWeightLimit, minWeightLimit]);

  const minMaturityLimit = maturityLimit?.maturityMin ?? 0;
  const maxMaturityLimit = maturityLimit?.maturityMax ?? 0;

  const maturityStep = useMemo(() => {
    const range = maxMaturityLimit - minMaturityLimit;
    const step = range / SLIDER_STEPS_COUNT;
    const roundedStep = Math.ceil(step / MATURITY_SLIDER_STEP_ROUND_VALUE) * MATURITY_SLIDER_STEP_ROUND_VALUE;

    return range < 10 ? 1 : roundedStep;
  }, [maxMaturityLimit, minMaturityLimit]);

  const isMaturityFilter = !!maturityLimit?.maturityMin && !!maturityLimit.maturityMax;
  const isMaturityRange = isMaturityFilter && maturityLimit.maturityMin !== maturityLimit.maturityMax;

  const isWeightFilter = !!weightLimit?.minWeight && !!weightLimit.maxWeight;
  const isWeightRange = isWeightFilter && weightLimit.maxWeight !== weightLimit.minWeight;

  const parsedColors = useMemo(() => {
    const filterColors = colorsData?.filter((color) => !!color).map((color) => ({ value: color, label: color })) || [];
    return [colorFilterAll, ...filterColors];
  }, [colorsData]);

  const parsedContinents = useMemo(() => {
    const filterContinents =
      continentsData?.filter((continent) => !!continent).map((continent) => ({ value: continent, label: continent })) ||
      [];
    return [continentFilterAll, ...filterContinents];
  }, [continentsData]);

  const parsedResistances = useMemo(() => {
    const filterResistances =
      resistancesData
        ?.filter((resistance) => !!resistance)
        .map((resistance) => ({ value: resistance, label: resistance })) || [];
    return [resistancesFilterAll, ...filterResistances];
  }, [resistancesData]);

  const parsedMinWeight = useMemo(() => {
    return [{ label: 'minWeight', value: minWeightLimit.toString() }];
  }, [minWeightLimit]);

  const parsedMaxWeight = useMemo(() => {
    return [{ label: 'maxWeight', value: maxWeightLimit.toString() }];
  }, [maxWeightLimit]);

  useEffect(() => {
    if (!colorsData || !continentsData || !resistancesData) {
      return;
    }

    const filters = new Map([
      ['geneticType', geneticFilterValues],
      ['color', parsedColors],
      ['continent', parsedContinents],
      ['sort', sortValues],
      ['minWeight', parsedMinWeight],
      ['maxWeight', parsedMaxWeight],
      ['resistances', parsedResistances],
      ['organic', organicFilterValues],
    ]);

    const parsedSearchParams = parseSearchParams(searchParams, filters, type);
    setSearchParams(parsedSearchParams, { replace: true });

    const geneticType = parsedSearchParams.get(FiltersNames.GENETIC_TYPE);
    const color = parsedSearchParams.get(FiltersNames.COLOR)?.split(',');
    const continent = parsedSearchParams.get(FiltersNames.CONTINENT)?.split(',');

    const resistances = parsedSearchParams.get(FiltersNames.RESISTANCES)?.split(',');
    const applyResistancesFilter = parsedSearchParams.get(FiltersNames.APPLY_RESISTANCES_FILTER) ? 'true' : null;

    const sort = parsedSearchParams.get(FiltersNames.SORT);

    const minWeight = parsedSearchParams.get(FiltersNames.MIN_WEIGHT);
    const maxWeight = parsedSearchParams.get(FiltersNames.MAX_WEIGHT);
    const applyWeightFilter = parsedSearchParams.get(FiltersNames.APPLY_WEIGHT_FILTER) ? 'true' : null;

    const minMaturity = parsedSearchParams.get(FiltersNames.MIN_MATURITY);
    const maxMaturity = parsedSearchParams.get(FiltersNames.MAX_MATURITY);
    const applyMaturityFilter = parsedSearchParams.get(FiltersNames.APPLY_MATURITY_FILTER) ? 'true' : null;

    const organic = parsedSearchParams.get(FiltersNames.ORGANIC);

    setParsedFilters({
      geneticType,
      color,
      continent,
      sort,
      minWeight,
      maxWeight,
      applyWeightFilter,
      minMaturity,
      maxMaturity,
      applyMaturityFilter,
      resistances,
      applyResistancesFilter,
      organic,
    });
  }, [
    type,
    colorsData,
    continentsData,
    parsedColors,
    parsedContinents,
    searchParams,
    setSearchParams,
    parsedMinWeight,
    parsedMaxWeight,
    resistancesData,
    parsedResistances,
  ]);

  useEffect(() => {
    if (parsedColors?.length <= 1 && parsedContinents?.length <= 1 && !colorsLoading && !colorsLoading) {
      // SPZ-1550: update
      const analyticsOptions = {
        query: searchParam ?? '',
        filter_color: [],
        filter_continent: [],
        filter_genetic_type: [],
        filter_resistances: [],
        include_products_with_incomplete_resistances: true,
        min_weight: '',
        max_weight: '',
        include_products_with_incomplete_weight: true,
        min_maturity: '',
        max_maturity: '',
        include_products_with_incomplete_maturity: true,
        results: [],
        results_length: 0,
      };

      tracker.track(AnalyticsName.SEARCH_PERFORMED, analyticsOptions);
    }
  }, [colorsLoading, parsedColors?.length, parsedContinents?.length, searchParam]);

  const isLoading = useMemo(
    () =>
      colorsLoading ||
      continentsLoading ||
      !parsedFilters ||
      weightLoading ||
      maturityLoading ||
      resistancesLoading ||
      organicLoading,
    [
      colorsLoading,
      continentsLoading,
      maturityLoading,
      organicLoading,
      parsedFilters,
      resistancesLoading,
      weightLoading,
    ],
  );

  return {
    parsedColors,
    parsedContinents,
    parsedResistances,
    parsedFilters,
    isLoading,
    isMaturityFilter,
    isOrganicFilter,
    isWeightFilter,
    isMaturityRange,
    isWeightRange,
    minWeightLimit,
    maxWeightLimit,
    weightStep,
    minMaturityLimit,
    maxMaturityLimit,
    maturityStep,
  };
};
