import { useCallback, useMemo } from 'react';

import { generatePath, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { ROUTES } from 'app/routes/constants';
import { UserData } from 'app/api/user/types';
import { useModal, useAuth, useDeviceType } from 'hooks';
import { NOTIFICATIONS_COUNT_QUERY_KEY } from 'layout/Notifications/constants';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';

interface UseUserMenuProps {
  onAction?: () => void;
}

export const useUserMenu = ({ onAction }: UseUserMenuProps) => {
  const { isMultiRole, isBuyer, activeUser, changeActiveUser } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();
  const { open: isModalOpen, handleOpenModal, handleCloseModal } = useModal();
  const { isMobileSize } = useDeviceType();

  const menuTitle = useMemo(() => {
    if (!isMultiRole) {
      return (activeUser as UserData)?.user.firstName;
    }

    return isBuyer ? 'Buyer' : 'Seller';
  }, [isBuyer, activeUser, isMultiRole]);

  const isAvatartButton = !isMultiRole && isMobileSize;

  const changeUser = useCallback(() => {
    onAction?.();
    changeActiveUser();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    queryClient.invalidateQueries({ queryKey: [NOTIFICATIONS_COUNT_QUERY_KEY] });
  }, [onAction, changeActiveUser, queryClient]);

  const handleOpenCompanyProfile = useCallback(() => {
    const path = generatePath(ROUTES.common.company, { displayId: activeUser?.company.displayId || '' });
    onAction?.();
    navigate(path);
    handleTrackLinkClickEvent({ text: 'Company Profile', type: AnalyticsLinkType.DROPDOWN, path });
  }, [activeUser?.company.displayId, onAction, handleTrackLinkClickEvent, navigate]);

  const handleOpenChangePassword = useCallback(() => {
    const path = generatePath(ROUTES.common.changePassword);
    onAction?.();
    navigate(path);
    handleTrackLinkClickEvent({ text: 'Change Password', type: AnalyticsLinkType.DROPDOWN, path });
  }, [onAction, handleTrackLinkClickEvent, navigate]);

  const handleGoToInventoryPage = useCallback(() => {
    const companyId = activeUser?.company.displayId || '';
    const path = generatePath(ROUTES.seller.edit._, {
      companyDisplayId: companyId,
    });
    onAction?.();
    navigate(path);
    handleTrackLinkClickEvent({ text: 'Inventory', type: AnalyticsLinkType.DROPDOWN, path });
  }, [activeUser?.company.displayId, onAction, handleTrackLinkClickEvent, navigate]);

  return {
    isAvatartButton,
    isBuyer,
    isModalOpen,
    isMultiRole,
    menuTitle,
    changeUser,
    handleCloseModal,
    handleOpenModal,
    handleOpenCompanyProfile,
    handleOpenChangePassword,
    handleGoToInventoryPage,
  };
};
