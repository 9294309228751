import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { snakeCase, startCase } from 'lodash';

import { Category, CompanyData } from 'app/api/company/types';
import { CategoryTags } from './constants';
import { styles } from './styles';

interface PageHeaderProps extends Pick<CompanyData, 'logo' | 'name' | 'address'> {
  categories?: Category[];
}

export const PageHeader: FC<PageHeaderProps> = ({ logo, name, address, categories }) => {
  return (
    <>
      <Box sx={{ ...styles.wrap }}>
        {logo && <Box component="img" loading="lazy" src={logo} alt={name || ''} sx={{ ...styles.image }} />}
        <Box sx={{ ...styles.nameWrap }}>
          <Typography component="h2" sx={{ ...styles.name }}>
            {name}
          </Typography>
          <Typography sx={{ ...styles.location }}>
            {address.city}, {address.country}
          </Typography>
        </Box>
      </Box>
      {categories && (
        <Box sx={{ ...styles.tagsWrap }}>
          {categories.map((category) => (
            <Typography key={category} component="span" sx={{ ...styles.tag }}>
              {CategoryTags[category] ?? startCase(snakeCase(category))}
            </Typography>
          ))}
        </Box>
      )}
    </>
  );
};
