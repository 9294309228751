import { FC } from 'react';

import { Box } from '@mui/material';

import { PageHeader } from 'components/PageHeader';
import { styles } from './styles';
import { EditProductsTable } from './components/EditProductsTable';

const messages = {
  title: 'Inventory',
  sub: 'Edit product availability or information',
};

export const EditProductsPage: FC = () => {
  return (
    <Box sx={{ ...styles.container }}>
      <PageHeader text={messages.title} subText={messages.sub} />
      <EditProductsTable />
    </Box>
  );
};

export default EditProductsPage;
