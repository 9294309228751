import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CommonModal, LoadingButton, TextFormField } from 'components';
import { useAuth, useDeviceType } from 'hooks';
import { requiredFieldValidation } from 'app/validation/validation';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { useFeedbackFormPopup } from './hooks';
import { bodyFieldRules } from './validation';
import { styles } from './styles';

interface FeedbackFormPopupProps {
  isOpen: boolean;
  handleCloseModal: () => void;
}

export const FeedbackFormPopup: FC<FeedbackFormPopupProps> = ({ isOpen, handleCloseModal }) => {
  const { activeUser } = useAuth();
  const isLoggedIn = !!activeUser;
  const { isMobileSize } = useDeviceType();
  const { isLoading, control, handleSubmit, handleConfirmRequest, handleClose } = useFeedbackFormPopup({
    handleCloseModal,
  });

  return (
    <CommonModal
      isOpen={isOpen}
      title="Can't find what you need?"
      handleClose={handleClose}
      paperStyles={{ ...(isMobileSize ? styles.paperMobile : styles.paper) } as SxPropsTypes}
    >
      <Typography sx={styles.subtitle}>
        If you know what you need but can&apos;t find it yet, let us know. We&apos;ll reach out to our network to find
        any requested products.
      </Typography>

      <form onSubmit={handleSubmit(handleConfirmRequest)}>
        <TextFormField
          name="msgBody"
          placeholder="I am looking for: product, quantity, price target..."
          multiline
          rows={5}
          inputWrapStyles={{ ...(styles.inputStyles as SxPropsTypes) }}
          control={control}
          rules={bodyFieldRules}
        />
        <Box sx={styles.actions}>
          {!isLoggedIn && (
            <TextFormField
              name="email"
              type="email"
              placeholder="Email"
              rows={5}
              fullWidth
              rules={{ required: requiredFieldValidation }}
              wrapStyles={{ ...(styles.emailContainer as SxPropsTypes) }}
              inputWrapStyles={{ ...(styles.emailInput as SxPropsTypes) }}
              control={control}
            />
          )}
          <LoadingButton
            type="submit"
            loading={isLoading}
            variant="contained"
            color="secondary"
            sx={styles.confirmButton}
          >
            Send to the Sproutzo team
          </LoadingButton>
        </Box>
      </form>
    </CommonModal>
  );
};
