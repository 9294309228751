import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { MessageListParams } from '@sendbird/chat/lib/__definition';
import { ClientSentMessages } from 'SendbirdUIKitGlobal';

import { SBChatMode, SBMessageCustomType } from 'app/api/SBChat';

interface UseSBConversationProps {
  channelUrl: string;
  mode: SBChatMode;
  selectedMessage?: ClientSentMessages;
  startingPoint?: number;
  handleClearMessageInput?: () => void;
  handleResetSelectedMessage?: () => void;
}

export const useSBConversation = ({
  channelUrl,
  mode,
  selectedMessage,
  startingPoint,
  handleClearMessageInput,
  handleResetSelectedMessage,
}: UseSBConversationProps) => {
  const [isSystemMessagesHidden, setIsSystemMessagesHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleSwitchMessagesView = useCallback(() => {
    setIsSystemMessagesHidden((state) => {
      if (
        handleResetSelectedMessage &&
        !state &&
        (selectedMessage?.customType === SBMessageCustomType.SYSTEM || !!selectedMessage?.silent)
      ) {
        handleResetSelectedMessage();
      }

      return !state;
    });
  }, [handleResetSelectedMessage, selectedMessage?.customType, selectedMessage?.silent]);

  useLayoutEffect(() => {
    if (mode === SBChatMode.MESSAGES_PAGE && channelUrl) {
      setIsLoading(true);
      setIsSystemMessagesHidden(true);
      handleClearMessageInput?.();
      setIsLoading(false);
    }
  }, [channelUrl, handleClearMessageInput, mode]);

  useEffect(() => {
    if (
      mode === SBChatMode.MESSAGES_PAGE &&
      isSystemMessagesHidden &&
      (selectedMessage?.customType === SBMessageCustomType.SYSTEM || !!selectedMessage?.silent)
    ) {
      setIsSystemMessagesHidden(false);
    }
  }, [handleSwitchMessagesView, isSystemMessagesHidden, mode, selectedMessage?.customType, selectedMessage?.silent]);

  const queries = useMemo(
    () =>
      isSystemMessagesHidden || isLoading
        ? {
            messageListParams: {
              ...(((selectedMessage?.customType !== SBMessageCustomType.SYSTEM && isSystemMessagesHidden) ||
                isSystemMessagesHidden) && {
                customTypesFilter: [SBMessageCustomType.USUAL],
              }),
              ...(!!startingPoint && {
                nextResultSize: 200,
                prevResultSize: 50,
              }),
            } as unknown as MessageListParams,
          }
        : {
            ...(!!startingPoint && {
              messageListParams: {
                nextResultSize: 200,
                prevResultSize: 50,
              } as unknown as MessageListParams,
            }),
          },
    [isLoading, isSystemMessagesHidden, selectedMessage?.customType, startingPoint],
  );

  const highlightedMessageId = useMemo(
    () => (mode === SBChatMode.MESSAGES_PAGE ? selectedMessage?.messageId : undefined),
    [mode, selectedMessage?.messageId],
  );

  return {
    highlightedMessageId,
    isLoading,
    isSystemMessagesHidden,
    queries,
    handleSwitchMessagesView,
  };
};
