import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  loaderWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  pageWrap: {
    marginTop: '18px',
    padding: '0px 64px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '-14px',
      padding: '0px',
    },
  },
};
