import { InfiniteData } from 'react-query';
import * as tracker from 'analytics/tracker';

import { ProductsListData } from 'app/api/products/types';
import { formatProductAnalyticsData } from './formatProductAnalyticsData';

export const getSearchPerformedEventResults = (data?: InfiniteData<ProductsListData>): tracker.apiObject[] => {
  const results = (data?.pages?.flatMap((item) => item?.results ?? []) ?? []).map((product) =>
    formatProductAnalyticsData({ product, isRank: true }),
  ) as unknown as tracker.apiObject[];

  return results;
};
