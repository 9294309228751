import { FC } from 'react';

import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';

import { reorderCategories } from 'hooks/api/useCropFamiliesQuery';
import { useDeviceType } from 'hooks';
import { siteMessages } from 'utils/siteMessages';

import { PageLoader } from '../../components/PageLoader';
import { ProductsSlider } from '../../components/ProductsSlider';
import { CompanySlider } from '../../components/CompanySlider';
import { HomePageBanner } from './components';
import { useBuyerHomePageHandlers } from './hooks';
import { styles } from './styles';
import { LeadingSuppliers } from './components/LeadingSuppliers';
import { Categories } from './components/Categories';
import { SimpleTradeBanner } from './components/SimpleTadeBanner';
import { AllInOnePlaceBanner } from './components/AllInOnePlaceBanner';
import { ChoiceCard } from './components/ChoiceCard';
import { SearchBar } from './components/SearchBar';

export const BuyerHomePage: FC = () => {
  const { isMobile } = useDeviceType();

  const { isLoading, homePageData, activeUser, newProducts, featuredProducts, featuredSuppliers } =
    useBuyerHomePageHandlers();

  const displayCropFamilies = reorderCategories(homePageData, true);

  if (isLoading) {
    return (
      <Box sx={{ ...styles.loaderWrap }}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <Box sx={{ ...styles.pageWrap }}>
      <Helmet>
        <title>{siteMessages.title}</title>
        <meta name="description" content={siteMessages.description} />
      </Helmet>

      <HomePageBanner />
      {!isMobile && <SearchBar />}
      {activeUser && <LeadingSuppliers />}
      <Categories />
      <ProductsSlider
        id="featured-products"
        name="Featured products"
        size="large"
        subText=" Instant access to thousands of varieties."
        products={featuredProducts}
      />

      <ProductsSlider
        id="new-arrivals"
        name="New arrivals"
        isLink={false}
        products={newProducts}
        slide={<ChoiceCard />}
        slidePosition={3}
      />
      {activeUser && (
        <CompanySlider companies={featuredSuppliers} name="Featured suppliers" pathName="Featured sellers" />
      )}
      <SimpleTradeBanner />
      {displayCropFamilies.slice(0, 2).map((section) => (
        <ProductsSlider key={section.id || section.name} {...section} isSmallTitle />
      ))}

      <AllInOnePlaceBanner />
      {displayCropFamilies.slice(2).map((section) => (
        <ProductsSlider key={section.id || section.name} {...section} isSmallTitle />
      ))}
    </Box>
  );
};
