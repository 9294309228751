import { FC, useEffect } from 'react';
import { Box, Popper, Typography } from '@mui/material';
import inEU from '@segment/in-eu';
import { rudderInitialize } from 'analytics';
import posthog from 'posthog-js';

// import { useDeviceType } from 'hooks';
import { CommonButton } from 'components/CommonButton';
import { useAuth, useModal } from 'hooks';

import { styles } from './styles';

const messages = {
  title: 'We value your privacy',
  message:
    'We use cookies to enhance your browsing experience and analyze site traffic.' +
    ' By clicking "Accept All", you consent to our use of cookies.',
};

// Sharing your cookies helps us improve site functionality and optimize your experience.
// Click Here to read our cookie policy.

export const CookieBanner: FC = () => {
  // const { isMobileSize } = useDeviceType();
  const { open, handleCloseModal, handleOpenModal } = useModal();
  const { isLoginLoading, activeUser } = useAuth();
  useEffect(() => {
    // Check if user is in EU
    // Check if user is not logged in
    const isClientInEU = inEU();
    const cookiePermissions = localStorage.getItem('cookiePermissions');
    if (isClientInEU && !isLoginLoading && !activeUser && !cookiePermissions) {
      handleOpenModal();
    } else if (!isClientInEU || (!isLoginLoading && activeUser) || cookiePermissions === 'accepted') {
      rudderInitialize();
    }
    // Check if has already accepted/rejected cookies
  }, [isLoginLoading, activeUser, handleOpenModal]);
  const handleRejectAll = () => {
    localStorage.setItem('cookiePermissions', 'rejected');
    handleCloseModal();
    posthog.opt_out_capturing();
  };
  const handleAcceptAll = () => {
    localStorage.setItem('cookiePermissions', 'accepted');
    rudderInitialize();
    handleCloseModal();
  };
  return (
    <Popper open={open} sx={styles.paper} placement="bottom-end">
      <Box sx={styles.container}>
        <Box sx={styles.content}>
          <Typography sx={styles.title}>{messages.title}</Typography>
          <Typography sx={styles.message}>{messages.message}</Typography>

          <Box sx={styles.btnContainer}>
            <CommonButton onClick={handleRejectAll} variant="outlined" color="primary">
              Reject All
            </CommonButton>
            <CommonButton onClick={handleAcceptAll} variant="contained" color="primary">
              Accept All
            </CommonButton>
          </Box>
        </Box>
      </Box>
    </Popper>
  );
};
