import { useCallback, useMemo } from 'react';

import { GroupChannel, BaseMessage } from '@sendbird/chat/lib/__definition';

import { Channel, MuteStatus, SBChannelMetaData } from 'app/api/SBChat';
import emptyChannelListImg from 'assets/images/emptyChannelList.png';
import { useAuth, useChannelMetaData } from 'hooks';
import { getLastMessageDate } from 'utils/getLastMessageDate';
import { getDateTime } from 'utils/getDateTime';

interface LastMessage extends BaseMessage {
  message?: string;
  name?: string;
  sender?: {
    nickname?: string;
  };
}

interface UseSBChannelPreviewProps {
  channel: GroupChannel;
  channelData?: Channel;
  handleSelectChannel: (id: string) => void;
}

export const useSBChannelPreview = ({ channel, channelData, handleSelectChannel }: UseSBChannelPreviewProps) => {
  const { isBuyer, activeUser } = useAuth();

  const currentUserSBId = activeUser?.user.chatId;
  const metaData: SBChannelMetaData = channel.cachedMetaData;
  const counter = channel.unreadMessageCount ?? 0;

  const { channelLogo, channelName } = useChannelMetaData({ channelSBName: channel?.name, isBuyer, metaData });

  const { lastMessage } = channel;

  const lastMessageDate = getLastMessageDate(lastMessage?.createdAt);
  const lastMessageTime = getDateTime(lastMessage?.createdAt);
  const lastMessageSender = (lastMessage as LastMessage)?.sender?.nickname;
  const lastMessageAuthorLabel = !lastMessage?.silent && !!lastMessageSender ? `${lastMessageSender}: ` : '';
  const lastMessageMessage =
    lastMessage?.messageType === 'file' ? (lastMessage as LastMessage)?.name : (lastMessage as LastMessage)?.message;
  const lastMessageBody = `${lastMessageAuthorLabel}${lastMessageMessage ?? ''}`;

  const memberData = useMemo(
    () => channelData?.members.find((member) => member.member.chatId === currentUserSBId),
    [channelData?.members, currentUserSBId],
  );

  const isMuted = useMemo(
    () => memberData?.muteStatus === MuteStatus.MUTE_ALL || memberData?.muteStatus === MuteStatus.MUTE_MESSAGES,
    [memberData?.muteStatus],
  );

  const isLogoPlaceholder = !channelLogo?.length;

  const handleClick = useCallback(() => {
    handleSelectChannel(channel.url);
  }, [channel.url, handleSelectChannel]);

  return {
    channelLogo: isLogoPlaceholder ? emptyChannelListImg : channelLogo,
    channelName,
    counter,
    isLogoPlaceholder,
    isMuted,
    lastMessageBody,
    lastMessageDate,
    lastMessageTime,
    handleClick,
  };
};
