import { requiredFieldValidation } from 'app/validation/validation';

export const bodyFieldRules = {
  required: requiredFieldValidation,
  maxLength: {
    value: 1000,
    message: 'Max length 1000 symbols',
  },
  validate: {
    noBlank: (value: string) => (!value.trim().length ? 'This field is required.' : true),
  },
};
