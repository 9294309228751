import { FC } from 'react';

import { Box, SxProps } from '@mui/material';

import { OrderQuoteProduct } from 'app/api/orders/types';
import { UnitsType } from 'app/api/quotes/types';
import { OrderCropsListItem } from 'components';
import { styles } from './styles';

interface CropsListProps {
  products: OrderQuoteProduct[];
  unitsType: UnitsType;
  isOrderPage?: boolean;
}

export const CropsList: FC<CropsListProps> = ({ products, unitsType, isOrderPage = false }) => {
  const minHeight = isOrderPage && products.length > 2 ? '120px' : '34px';

  return (
    <Box sx={{ ...(styles.listWrap(isOrderPage, minHeight) as SxProps) }}>
      {products.map(({ id, product, acceptedPrice, amount }) => (
        <OrderCropsListItem
          key={id}
          cropName={product.crop}
          variety={product?.variety}
          name={product?.name}
          price={acceptedPrice}
          amount={amount}
          unitsType={unitsType}
        />
      ))}
    </Box>
  );
};
