import { FC, useMemo } from 'react';

import { format } from 'date-fns';
import { ClientAdminMessage, ClientFileMessage, ClientUserMessage } from 'SendbirdUIKitGlobal';

import { SBChatMode, SBClientUserMessage, SBMemberMetaData } from 'app/api/SBChat';
import { useAuth } from 'hooks';
import { SBUserMessage } from '../SBUserMessage';
import { SBAdminMessage } from '../SBAdminMessage';
import { SBFileMessage } from '../SBFileMessage';

interface SBMessageProps {
  isHighlighted: boolean;
  message: ClientUserMessage | ClientAdminMessage | ClientFileMessage;
  mode: SBChatMode;
}

export const SBMessage: FC<SBMessageProps> = ({ message, mode, isHighlighted }) => {
  const { sendbirdUserId, activeUserCompanyId } = useAuth();

  const data = message as unknown as SBClientUserMessage;

  const date = useMemo(() => {
    return format(new Date(message.createdAt), 'h:mm aa');
  }, [message]);

  const isCurrentUserAuthor = data?.sender?.userId === sendbirdUserId;
  const isCurrentCompanyAuthor = (data?.sender?.metaData as SBMemberMetaData)?.company_id === activeUserCompanyId;

  const messageComponent = useMemo(() => {
    if (data.messageType === 'user' && !message.silent) {
      return (
        <SBUserMessage
          avatarSrc={data?.sender?.plainProfileUrl}
          userName={data?.sender?.nickname}
          body={data.message}
          date={date}
          isCurrentUser={isCurrentUserAuthor}
          isCurrentCompany={isCurrentCompanyAuthor}
          isHighlighted={isHighlighted}
          mode={mode}
        />
      );
    }

    if (data.messageType === 'admin' || (data.messageType === 'user' && !!message.silent)) {
      return <SBAdminMessage body={data.message} date={date} mode={mode} isHighlighted={isHighlighted} />;
    }

    if (data.messageType === 'file') {
      return (
        <SBFileMessage
          isCurrentUser={isCurrentUserAuthor}
          isCurrentCompany={isCurrentCompanyAuthor}
          isHighlighted={isHighlighted}
          sender={data.sender}
          message={message as ClientFileMessage}
          date={date}
        />
      );
    }

    return <></>;
  }, [
    mode,
    data.messageType,
    data.sender,
    data.message,
    message,
    date,
    isCurrentUserAuthor,
    isCurrentCompanyAuthor,
    isHighlighted,
  ]);

  return <>{messageComponent}</>;
};
