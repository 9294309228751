import { FC } from 'react';

import { Sender } from '@sendbird/chat/lib/__definition';
import { Box, SxProps } from '@mui/material';
import FileMessageItemBody from '@sendbird/uikit-react/ui/FileMessageItemBody';
import { ClientFileMessage } from 'SendbirdUIKitGlobal';

import { SBMessageContainer } from '../SBMessageContainer';
import { SBMessageHeader } from '../SBMessageHeader';
import { SBMessageDate } from '../SBMessageDate';
import { styles } from './styles';

interface SBFileMessageProps {
  isCurrentUser: boolean;
  isCurrentCompany: boolean;
  isHighlighted: boolean;
  date: string;
  message: ClientFileMessage;
  sender: Sender;
}

export const SBFileMessage: FC<SBFileMessageProps> = ({
  isCurrentUser,
  isCurrentCompany,
  isHighlighted,
  date,
  message,
  sender,
}) => {
  return (
    <SBMessageContainer isCurrentUser={isCurrentUser}>
      <SBMessageHeader
        userName={sender.nickname}
        avatar={sender.plainProfileUrl}
        isCurrentUser={isCurrentUser}
        isCurrentCompany={isCurrentCompany}
      />
      <Box sx={styles.wrap(isCurrentUser)}>
        <Box sx={styles.bodyWrap(isCurrentUser || isCurrentCompany, isHighlighted) as SxProps}>
          <FileMessageItemBody message={message} mouseHover={false} />
        </Box>
        <SBMessageDate date={date} />
      </Box>
    </SBMessageContainer>
  );
};
