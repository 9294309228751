import { useMemo, useState } from 'react';

import { useQuery } from 'react-query';

import { getFeaturedSellers, getHomePageData, HomePageSection, Product } from 'app/api/products';
import { shuffleArray } from 'utils/shuffleArray';
import { Company } from 'app/api/company';
import { useAuth } from 'hooks';
import { UserData } from 'app/api/user/types';

interface BuyerHomePageHandlers {
  activeUser: UserData | null;
  isLoading: boolean;
  homePageData: HomePageSection[];

  featuredProducts: Product[];
  newProducts: Product[];
  featuredSuppliers: Company[];
}

// NOTE: This is a hard-coded list of featured suppliers
const featuredSuppliersOrder = [
  185, // basf
  222, // east west
  188, // us agriseeds
  191, // fito
  219, // barenburg
  8, // may
  17, // chia thai
  49, // hollar
  104, // graines voltz
  18, // known
  24, // indo
  48, // condor
  146, // feltrin
  135, // seed bound
  16, // ramiro
  25, // terranova
  50, // asia
  26,
  28,
  2,
  30,
  23,
  29,
  66,
  22,
  84,
];
const featuredSuppliersOrderSet = new Set(featuredSuppliersOrder);

export const useBuyerHomePageHandlers = (): BuyerHomePageHandlers => {
  const [data, setData] = useState<HomePageSection[]>([]);
  const [featuredSuppliers, setFeaturedSuppliers] = useState<Company[]>([]);
  const [featuredProducts, setFeaturedProducts] = useState<Product[]>([]);
  const [newProducts, setNewProducts] = useState<Product[]>([]);
  const { activeUser } = useAuth();

  const { isLoading, isFetching } = useQuery(['home-page'], () => getHomePageData(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // to show the Vagetable at the top of the crop families list
      // https://yalantis.atlassian.net/browse/SPZ-1953
      const vegetableIndex = data.cropFamilies.findIndex((family) => family.name.toLowerCase() === 'vegetable');
      const vegetableSection = data.cropFamilies.splice(vegetableIndex, 1)[0];
      data.cropFamilies.unshift(vegetableSection);
      setFeaturedProducts(shuffleArray<Product>(data.featuredProducts));
      setNewProducts(shuffleArray<Product>(data.newProducts));
      setData(data.cropFamilies);
    },
  });

  const { isLoading: isFeaturedSellersLoading, isFetching: isFeaturedSellersFetching } = useQuery(
    ['featured-sellers'],
    () => getFeaturedSellers(),
    {
      refetchOnWindowFocus: false,
      enabled: !!activeUser,
      onSuccess: (data) => {
        const allCompanies = data.filter((supplier) => !!supplier.logo);
        const companyMap = allCompanies.reduce((acc, supplier) => {
          return { ...acc, [supplier.id]: supplier };
        }, {} as { [id: number]: Company });
        const orderedCompanies = featuredSuppliersOrder.map((id) => companyMap[id]).filter(Boolean);
        allCompanies.forEach((company) => {
          if (!featuredSuppliersOrderSet.has(company.id)) {
            orderedCompanies.push(company);
          }
        });
        setFeaturedSuppliers(orderedCompanies);
      },
    },
  );

  const filteredData = useMemo(() => [...data].filter((section) => !!section.products.length), [data]);

  const homePageData = useMemo(
    () =>
      filteredData.map(({ products, ...section }) => ({
        products: shuffleArray<Product>(products),
        ...section,
      })),
    [filteredData],
  );

  return {
    activeUser,
    isLoading: isLoading || isFetching || isFeaturedSellersLoading || isFeaturedSellersFetching,
    homePageData,
    newProducts,
    featuredSuppliers,
    featuredProducts,
  };
};
