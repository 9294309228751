import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  // Shared styles
  topWelcome: {
    margin: '0px 0px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  welcome: {
    color: '#B3B3B3',
  },
  welcomeLogo: {
    width: '300px',
  },

  //
  title: {},
  buttonsWrap: {},
  loginButton: {},
  requestAccess: {},
  signin: {},
  modalPaper: {
    width: '600px',
  },
  closeBtn: {
    marginBottom: '0px',
  },

  // Request modal
  requestContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 16px',
  },
  requestWelcome: {
    margin: '8px 0px 16px',
  },
  businessContainer: {
    margin: '8px 0px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  businesses: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  business: (isSelected: boolean) => ({
    maxWidth: '184px',
    minWidth: '184px',
    boxSizing: 'border-box',
    flex: 1,
    textWrap: 'nowrap',
    textAlign: 'center',
    border: '1px solid #E0E0E0',
    padding: '4px 8px',
    borderRadius: '16px',
    fontSize: '16px',
    fontWeight: 500,
    cursor: 'pointer',
    color: customTheme.palette.primary.main,
    backgroundColor: customTheme.custom.gray.G50,
    ...(isSelected && {
      color: customTheme.custom.white,
      backgroundColor: customTheme.custom.organic,
    }),
  }),
  note: {
    marginBottom: '8px',
  },
  rowContainer: {
    display: 'flex',
    gap: '8px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexDirection: 'column',
      gap: '0px',
    },
  },
  rowItem: {
    flex: 1,
    marginBottom: '12px',
  },
  selectInput: {
    padding: '0px',
    fontSize: '12px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minHeight: '42px',
    },
  },
  requestAccessConfirmBtn: {
    fontWeight: 500,
    fontSize: '16px',
    margin: '16px 0px',
  },
  // Login styles
  loginContainer: {
    maxWidth: '350px',
    margin: '0px auto 32px',
  },
  loginText: {
    fontWeight: 600,
    fontSize: '24px',
    margin: '0px 0px 16px',
  },
  loginBtns: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
  },
  forgotPasswordButton: {
    color: customTheme.custom.gray.G500,
  },
  // Welcome styles
  welcomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '350px',
    margin: '0px auto 48px',
  },
  welcomeNote: {
    width: '100%',
    color: '#555',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 400,
    margin: '0px 0px 16px',
  },
  welcomeBtn: {
    width: '100%',
  },
  requestAccessBtn: {
    marginBottom: '16px',
  },
};
