import { useCallback } from 'react';

import { useForm } from 'react-hook-form';

import { FeedbackFormValues } from '../types';
import { useFeedbackFormMutation } from './useFeedbackFormMutation';

interface UseFeedbackFormPopupProps {
  handleCloseModal: () => void;
}

export const useFeedbackFormPopup = ({ handleCloseModal }: UseFeedbackFormPopupProps) => {
  const { control, reset, handleSubmit } = useForm<FeedbackFormValues>();

  const handleClose = useCallback(() => {
    handleCloseModal();
    reset();
  }, [handleCloseModal, reset]);

  const { isCreateFeedbackFormLoading, handleCreateProductNotFoundRequest } = useFeedbackFormMutation({
    onSuccessAction: handleClose,
  });

  const handleConfirmRequest = useCallback(
    (values: FeedbackFormValues) => {
      handleCreateProductNotFoundRequest(values);
    },
    [handleCreateProductNotFoundRequest],
  );

  return {
    isLoading: isCreateFeedbackFormLoading,
    control,
    handleSubmit,
    handleConfirmRequest,
    handleClose,
  };
};
